import React from 'react';
import {useRecoilValue} from 'recoil';

import ItemDetailEditForm from './ItemDetailEditForm';
import SideBarTopMenu from './SideBarTopMenu';
import StreamsEditor from './StreamsEditor';
import Statistics from './Statistics';
import {getSelectedItemSelector, getStreamsOrThrow} from '../../state/selectors';
import {SIDEBAR_CONTENT, sideBarContentAtom} from '../../state/atoms.ui';
import SaveErrorAndExport from './SaveErrorAndExport';
import UserProfile from './UserProfile';
import Help from './Help';
import LifeExportButton from './LifeExportButton';
import useActionModifyItem from '../../state/actions/modifyItem';
import useActionTrashItem from '../../state/actions/trashItem';
import useActionSetStreams from '../../state/actions/setStreams';
import useActionDeleteStream from '../../state/actions/deleteStream';
import useActionRestoreItem from '../../state/actions/restoreItem';

import {StyledSideBar, StyledSideBarPart} from './_styled';
import Trash from './Trash';
import {trashedItemsAtom} from '../../state/atoms';
import BirthSelector from './BirthSelector';

const SideBar = () => {
  const sideBarContentToDisplay = useRecoilValue(sideBarContentAtom);
  const selectedItem = useRecoilValue(getSelectedItemSelector);
  const streams = useRecoilValue(getStreamsOrThrow);
  const trashedItems = useRecoilValue(trashedItemsAtom);
  const actModifyItem = useActionModifyItem();
  const actTrashItem = useActionTrashItem();
  const actRestoreItem = useActionRestoreItem();
  const actSetStreams = useActionSetStreams();
  const actDeleteStream = useActionDeleteStream();

  return (
    <StyledSideBar>
      {sideBarContentToDisplay === SIDEBAR_CONTENT.ERROR && <SaveErrorAndExport />}

      {sideBarContentToDisplay !== SIDEBAR_CONTENT.ERROR && (
        <React.Fragment>
          <SideBarTopMenu />

          {sideBarContentToDisplay === SIDEBAR_CONTENT.ITEM && selectedItem && (
            <ItemDetailEditForm
              item={selectedItem}
              onSave={actModifyItem}
              onDelete={actTrashItem}
            />
          )}

          {sideBarContentToDisplay === SIDEBAR_CONTENT.ITEM && !selectedItem && (
            <p>No item selected</p>
          )}

          {sideBarContentToDisplay === SIDEBAR_CONTENT.SETTINGS && (
            <React.Fragment>
              <h2>Settings</h2>
              <StyledSideBarPart>
                <StreamsEditor
                  streams={streams}
                  onStreamsChange={actSetStreams}
                  onStreamDelete={actDeleteStream}
                />
              </StyledSideBarPart>

              <StyledSideBarPart>
                <BirthSelector />
              </StyledSideBarPart>

              <StyledSideBarPart>
                <Statistics />
              </StyledSideBarPart>

              <StyledSideBarPart>
                <UserProfile />
              </StyledSideBarPart>

              <StyledSideBarPart>
                <h3>Export</h3>
                <LifeExportButton />
              </StyledSideBarPart>
            </React.Fragment>
          )}

          {sideBarContentToDisplay === SIDEBAR_CONTENT.TRASH && (
            <Trash trashedItems={trashedItems} onRestoreItem={actRestoreItem} />
          )}

          {sideBarContentToDisplay === SIDEBAR_CONTENT.HELP && <Help />}
        </React.Fragment>
      )}
    </StyledSideBar>
  );
};

export default SideBar;
