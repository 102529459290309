import React from 'react';
import ReactDOM from 'react-dom/client';
import {RecoilRoot} from 'recoil';

import 'normalize.css';
import 'react-datepicker/dist/react-datepicker.css';

import './resources/font/css/my-life.css';
import './resources/font/css/animation.css';

import App from './components/App';
import LifePersistorObserver from './state/LifePersistorObserver';

import './data/service/interceptHTTP'; // during dev/test, we want to intercept our HTTP calls

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <RecoilRoot>
    <LifePersistorObserver />
    <App />
  </RecoilRoot>
);
