import React, {useEffect, useState} from 'react';
import {HexColorPicker} from 'react-colorful';
import {useRecoilState} from 'recoil';

import IconButton from '../commons/IconButton';
import Button from '../commons/Button';
import {hasUnsavedItemChangesAtom} from '../../state/atoms.ui';
import Stream, {createNewStream} from '../../domain/Stream';
import WithId from '../../domain/WithId';
import moveElementInArray from '../../data/moveElementInArray';

import {
  StyledStream,
  StyledStreamEditor,
  StyledStreamsEditor,
  StyledEditorButtonGroup,
  StyledStreamsSelector,
  StyledColorPickerWrapper,
  StyledColorPickerItemPreview
} from './_styled';
import {StyledInput} from '../commons/_styled';

interface StreamListItem extends WithId {
  title: string;
  color: string;
  itemCount: number;
}

interface StreamsEditorTypes {
  streams: Stream[];
  onStreamsChange: (modifiedStreams: Stream[]) => void;
  onStreamDelete: (streamIdToDelete: string) => void;
}

const StreamsEditor = ({streams, onStreamsChange, onStreamDelete}: StreamsEditorTypes) => {
  const [streamList, setStreamList] = useState<StreamListItem[]>(deriveStreamList());
  const [selectedStream, setSelectedStream] = useState<StreamListItem>(
    deriveInitialSelectedStream()
  );
  const [hasUnsavedChanges, setHasUnsavedChanges] = useRecoilState(hasUnsavedItemChangesAtom);

  useEffect(() => {
    setStreamList(deriveStreamList());
    setSelectedStream(deriveSelectedStream());
  }, [streams]);

  if (!streamList || !selectedStream) {
    return null;
  }

  const canDeleteSelectedStream = streamList.length > 1 && selectedStream.itemCount < 1;

  return (
    <StyledStreamsEditor>
      <h3>Streams</h3>
      <StyledStreamsSelector>
        {streamList.map((stream) => (
          <StyledStream
            onClick={() => setSelectedStream(stream)}
            key={stream.id}
            color={stream.id === selectedStream.id ? selectedStream.color : stream.color}
            $selected={stream.id === selectedStream.id}
          >
            {stream.id === selectedStream.id ? selectedStream.title : stream.title}
          </StyledStream>
        ))}
      </StyledStreamsSelector>

      <StyledEditorButtonGroup>
        <IconButton className="icon-up-open" onClick={onUpButtonClick} />
        <IconButton className="icon-down-open" onClick={onDownButtonClick} />
        <IconButton className="icon-plus" onClick={onAddButtonClick} />
      </StyledEditorButtonGroup>

      {selectedStream && (
        <StyledStreamEditor color={selectedStream.color}>
          <StyledInput
            type="text"
            value={selectedStream.title}
            onChange={onTitleChange}
            placeholder="Title"
          />

          <StyledColorPickerWrapper>
            <HexColorPicker color={selectedStream.color} onChange={onColorChange} />

            <StyledColorPickerItemPreview color={selectedStream.color}>
              <span>Preview</span>
              <div>This is how it will look like...</div>
            </StyledColorPickerItemPreview>
          </StyledColorPickerWrapper>

          <StyledEditorButtonGroup>
            <Button onClick={onTrashButtonClick} disabled={!canDeleteSelectedStream}>
              <i className="icon-trash" /> Delete
            </Button>

            {hasUnsavedChanges && (
              <Button onClick={onDiscardButtonClick}>
                <i className="icon-cancel" /> Discard Changes
              </Button>
            )}
            {hasUnsavedChanges && (
              <Button onClick={onSaveButtonClick} primary>
                <i className="icon-floppy" /> Save
              </Button>
            )}
          </StyledEditorButtonGroup>
        </StyledStreamEditor>
      )}
    </StyledStreamsEditor>
  );

  function deriveStreamList(): StreamListItem[] {
    return streams.map(toStreamListItem);
  }

  function deriveInitialSelectedStream(): StreamListItem {
    if (!streams || !streams.length) {
      throw new Error('We expected to have a non-empty list of streams');
    }

    return toStreamListItem(streams[0]);
  }

  function toStreamListItem(str: Stream): StreamListItem {
    return {
      id: str.id,
      title: str.title,
      color: str.color,
      itemCount: str.items ? str.items.length : 0
    };
  }

  function deriveSelectedStream() {
    if (selectedStream && streamList.some((s) => s.id === selectedStream.id)) {
      return selectedStream;
    }
    return deriveInitialSelectedStream();
  }

  function onAddButtonClick() {
    const newStreamListItem = toStreamListItem(createNewStream());
    setSelectedStream(newStreamListItem);
    setStreamList([...streamList, newStreamListItem]);
    setHasUnsavedChanges(true);
  }

  function onTrashButtonClick() {
    if (canDeleteSelectedStream) {
      onStreamDelete(selectedStream.id);
    }
  }

  function onSaveButtonClick() {
    const streamsToSave = updateStreamsWithModifiedStreams(
      streams,
      streamList.map((s) => (s.id === selectedStream.id ? selectedStream : s))
    );
    onStreamsChange(streamsToSave);
    setHasUnsavedChanges(false);
  }

  function onDiscardButtonClick() {
    const unmodifiedListItem = streamList.find((s) => s.id === selectedStream.id);
    if (!unmodifiedListItem) {
      throw new Error(
        ` id of currently "selectedStream" object ${selectedStream.id} is not found in list`
      );
    }
    setSelectedStream(unmodifiedListItem);
    setStreamList(deriveStreamList());
    setHasUnsavedChanges(false);
  }

  function onColorChange(newHexValue: string) {
    const modifiedStream: StreamListItem = {...selectedStream, color: newHexValue};
    setSelectedStream(modifiedStream);
    setHasUnsavedChanges(true);
  }

  function onTitleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const modifiedStream = {...selectedStream};
    modifiedStream.title = e.target.value;
    setSelectedStream(modifiedStream);
    setHasUnsavedChanges(true);
  }

  function onUpButtonClick() {
    setStreamList(moveElementInArray(streamList, selectedStream.id, -1));
    setHasUnsavedChanges(true);
  }

  function onDownButtonClick() {
    const modifiedStreamList = moveElementInArray(streamList, selectedStream.id, 1);
    setStreamList(modifiedStreamList);
    setHasUnsavedChanges(true);
  }
};

export default StreamsEditor;

type StreamsByIdMap = {
  [id: string]: Stream;
};

export const updateStreamsWithModifiedStreams = (
  streams: Stream[],
  modifiedStreamList: StreamListItem[]
): Stream[] => {
  const originalStreamsById = streams.reduce((total: StreamsByIdMap, current) => {
    total[current.id] = current;
    return total;
  }, {});

  // important to take new order of streams from "modifiedStreamList"
  return modifiedStreamList.map((stream): Stream => {
    if (originalStreamsById[stream.id]) {
      return {
        ...originalStreamsById[stream.id],
        // only take the two properties that can be edited
        title: stream.title,
        color: stream.color
      };
    } else {
      return {
        // if it is a new stream, originalStreamsById will not contain a matching object. make sure we have a "id" and a "items" array
        id: stream.id,
        items: [],
        // only take the two properties that can be edited
        title: stream.title,
        color: stream.color
      };
    }
  });
};
