import Album from '../../domain/Album';

export default interface PartialAlbumList {
  albums: Album[];
  nextPageToken?: string;
  allLoaded?: boolean;
}

export const emptyAlbumList = (): PartialAlbumList => ({
  albums: [],
  allLoaded: false
});
