import ContextPersistorService from '../ContextPersistorService';
import UserContext from '../../../domain/UserContext';
import {b64_to_utf8, utf8_to_b64} from './b64';

const LSTORAGE_PROP_PREFIX = 'MY_LIFE';

/**
 * Persist and loads the current UserContext to/from LocalStorage.
 * This is used in production as well.
 */
const lsContextPersistorService: ContextPersistorService = {
  persistContext,
  loadContext
};

export default lsContextPersistorService;

/**
 * Persists the given UserContext to localstorage as Base64 encoded string
 */
function persistContext(ctx: UserContext | undefined) {
  if (ctx) {
    localStorage.setItem(`${LSTORAGE_PROP_PREFIX}:ctx`, utf8_to_b64(JSON.stringify(ctx)));
  } else {
    localStorage.setItem(`${LSTORAGE_PROP_PREFIX}:ctx`, '');
  }
}

/**
 * Loads the UserContext from localstorage if there is any. returns undefined otherwise
 */
function loadContext(): UserContext | undefined {
  const persistedState = localStorage.getItem(`${LSTORAGE_PROP_PREFIX}:ctx`);
  if (!persistedState) {
    return undefined;
  }

  try {
    const parsedContent: any = JSON.parse(b64_to_utf8(persistedState));
    return parsedContent as UserContext;
  } catch (e) {
    console.error(e);
    return undefined;
  }
}
