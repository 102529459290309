import React from 'react';
import {useRecoilState} from 'recoil';
import DatePicker from 'react-datepicker';

import {birthAtom} from '../../state/atoms';
import {StyledBirthSelector, StyledDateInputWrapper} from './_styled';

const BirthSelector = () => {
  const [birth, setBirth] = useRecoilState(birthAtom);

  return (
    <StyledBirthSelector>
      <h3>Date of Birth</h3>

      <StyledDateInputWrapper>
        <DatePicker
          calendarStartDay={1}
          dateFormat="dd.MM.yyyy"
          onChange={(newBirthDate) => {
            if (newBirthDate) {
              setBirth(newBirthDate);
            }
          }}
          placeholderText="Date of Birth"
          selected={birth}
        />
      </StyledDateInputWrapper>
    </StyledBirthSelector>
  );
};
export default BirthSelector;
