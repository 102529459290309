import React from 'react';

import LifeExportButton from './LifeExportButton';

import {StyledSaveErrorAndExport} from './_styled';

const SaveErrorAndExport = () => {
  return (
    <StyledSaveErrorAndExport>
      <h2>
        <i className="icon-attention"></i>
        Error while saving Life
      </h2>

      <div>
        <p>We are sorry, saving your life to storage failed...</p>

        <p>Please export your life to a local file in order to not loose any data...</p>

        <LifeExportButton />
      </div>
    </StyledSaveErrorAndExport>
  );
};

export default SaveErrorAndExport;
