import React, {useEffect, useState} from 'react';
import {useRecoilValue} from 'recoil';

import Spinner from './Spinner';
import FavIcon from './FavIcon';
import {getUserContextOrThrow} from '../../state/selectors';
import AlbumInfo from '../../data/service/AlbumInfo';
import GooglePhotosService from '../../data/service/impl/google/GooglePhotosService';
import useLoadPhotosFromAlbum from '../life/useLoadPhotosFromAlbum';

import {
  StyledPhotoAlbumLink,
  StyledPhotoDisplay,
  StyledPhotoDisplayInner,
  StyledPhotoItem
} from './_styled';

interface PhotoDisplayTypes {
  albumId: string;
}

const PhotoDisplay = ({albumId}: PhotoDisplayTypes) => {
  const [albumInfo, setAlbumInfo] = useState<AlbumInfo | undefined>(undefined);
  const userContext = useRecoilValue(getUserContextOrThrow);
  const [pickedPhotos, allPhotosLoaded] = useLoadPhotosFromAlbum(
    userContext.accessToken,
    albumId,
    8 // howManyPhotosToPick
  );

  useEffect(() => {
    let componentStillMounted = true;

    setAlbumInfo(undefined);
    GooglePhotosService.loadAlbumInfo(userContext.accessToken, albumId)
      .then((aInfo) => {
        if (componentStillMounted) {
          setAlbumInfo(aInfo);
        }
      })
      .catch((err) => console.error(err));

    return () => {
      // useEffect cleanup
      componentStillMounted = false;
    };
  }, [albumId, userContext.accessToken]);

  return (
    <StyledPhotoDisplay>
      <StyledPhotoDisplayInner>
        {(!allPhotosLoaded || !albumInfo) && <Spinner />}
        {allPhotosLoaded && albumInfo && (
          <React.Fragment>
            {pickedPhotos.map((p, index) => (
              <StyledPhotoItem key={`photo:${albumId}:${index}`}>
                <img src={`${p}=h160-w160-c`} alt={index.toString()} />
              </StyledPhotoItem>
            ))}
            <StyledPhotoAlbumLink $size={160}>
              <a href={albumInfo.url} target="_blank" rel="noopener noreferrer">
                <FavIcon url="https://photos.google.com" /> {albumInfo.title}
              </a>
            </StyledPhotoAlbumLink>
          </React.Fragment>
        )}
      </StyledPhotoDisplayInner>
    </StyledPhotoDisplay>
  );
};

export default PhotoDisplay;
