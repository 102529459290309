import React, {useMemo} from 'react';

import {useCalculateMonthsTimeLineWidth} from './spotlightLayouter';
import {getMonthsInfoForSpotlightYear} from '../../domain/MonthInfo';

import {StyledTimeLine, StyledYearMarker} from './_styled';

interface MonthsTimeLineTypes {
  year: number;
}

const MonthsTimeLine = ({year}: MonthsTimeLineTypes) => {
  const timeLineWidth = useCalculateMonthsTimeLineWidth(year);
  const months = useMemo(() => getMonthsInfoForSpotlightYear(year), [year]);

  return (
    <StyledTimeLine style={{width: `${timeLineWidth}px`}}>
      {months.map((m) => (
        <StyledYearMarker
          style={{width: `${m.daysCount * 10}px`}}
          key={`month-marker:${m.month}`}
          $faded={m.year !== year}
        >
          {m.month}
        </StyledYearMarker>
      ))}
    </StyledTimeLine>
  );
};

export default MonthsTimeLine;
