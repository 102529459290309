import React from 'react';

import {StyledButton, StyledPrimaryButton} from './_styled';

interface ButtonTypes {
  onClick: () => void;
  children?: React.ReactNode;
  disabled?: boolean;
  primary?: boolean;
}

const Button = ({onClick, children, disabled, primary}: ButtonTypes) => {
  const ButtonComponent = primary ? StyledPrimaryButton : StyledButton;

  return (
    <ButtonComponent disabled={disabled} onClick={onClick}>
      {children}
    </ButtonComponent>
  );
};

export default Button;
