import {startDateItemComparator} from './startDateItemComparator';
import {isAfter, differenceInDays} from 'date-fns';
import LifeItem from '../domain/LifeItem';

/**
 * transforms a flat list of items into "rows" of items for better visual appearance.
 * This is a layout-ing step, and does not change any internal item data.
 * It is about "where to place items" in the timeline (years or year) on the "canvas".
 *
 */
export function itemsToRows<T extends LifeItem>(
  items: T[],
  minimumDayDifference: number = 320
): T[][] {
  const rows: T[][] = [[]];

  // first, sort them by starting date
  const sortedItems = [...items];
  sortedItems.sort(startDateItemComparator);

  // now loop over flat list of items and place them in "rows"
  sortedItems.forEach((itemToPlace) => {
    if (rows[0].length === 0) {
      rows[0].push(itemToPlace);
      return;
    }

    const placedInExistingRows = doPlaceInExistingRowsIfPossible(rows, itemToPlace);

    if (!placedInExistingRows) {
      rows.push([itemToPlace]);
    }
  });

  return rows;

  /**
   *
   */
  function doPlaceInExistingRowsIfPossible(rows: LifeItem[][], itemToPlace: LifeItem) {
    return rows.find((row) => {
      if (canPlaceItem(row, itemToPlace)) {
        row.push(itemToPlace);
        return true;
      }
      return false;
    });
  }

  function canPlaceItem(row: LifeItem[], itemToPlace: LifeItem) {
    if (row.length < 1) {
      return true;
    }

    const itemAlreadyInRow = row[row.length - 1];

    // console.log(`row ${index}. itemToPlace ${itemToPlace.title}. itemInRow ${itemAlreadyInRow.title}`);

    if (itemAlreadyInRow.endDateObj) {
      if (isAfter(itemToPlace.startDateObj, itemAlreadyInRow.endDateObj)) {
        const deltaPrevStartDateAndNewItemStartDate = differenceInDays(
          itemToPlace.startDateObj,
          itemAlreadyInRow.startDateObj
        );
        if (deltaPrevStartDateAndNewItemStartDate > minimumDayDifference) {
          return true;
        }
      }
      return false;
    }

    if (itemAlreadyInRow.ongoing) {
      return false;
    }

    return (
      differenceInDays(itemToPlace.startDateObj, itemAlreadyInRow.startDateObj) >
      minimumDayDifference
    );
  }
}
