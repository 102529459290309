import styled from 'styled-components';
import tinycolor from 'tinycolor2';

import {StyledPhotoAlbumLink, StyledPhotoDisplay, StyledPhotoItem} from '../../commons/_styled';

interface StyledStreamItemBarProps {
  $color: string;
}

export const StyledStreamItemBar = styled.div<StyledStreamItemBarProps>`
  cursor: pointer;
  height: 12px;
  background-color: ${(props) => props.$color} !important;
`;

export const StyledStreamItemData = styled.div`
  margin-top: 30px;
  padding: 8px;
  display: inline-block;
  border-radius: 0 8px 8px 0;
  max-width: 300px;
  cursor: pointer;

  > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const StyledSpotlightStreamItemData = styled(StyledStreamItemData)`
  max-width: 488px;

  > div {
    white-space: normal;
    text-overflow: unset;
    margin: 8px 0;
  }
`;

export const StyledSpotlightStreamItemDescription = styled.div``;
export const StyledSpotlightStreamItemAge = styled.div`
  margin-top: 8px;
`;

export const StyledStreamItemDescription = styled.div`
  display: ${(props) => (props.theme.zoomedOut ? 'none' : 'block')};
`;

export const StyledStreamItemTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 600;

  > i {
    font-size: x-small;
  }
`;

interface StyledStreamItemProps {
  $left: number;
  $highlighted: boolean;
  $color: string;
}

export const StyledStreamItem = styled.div<StyledStreamItemProps>`
  position: absolute;
  left: ${(props) => props.$left}px;

  border-left: 2px solid ${(props) => props.$color} !important;

  ${StyledStreamItemData} {
    font-size: ${(props) => (props.theme.zoomedOut ? 'small' : 'inherit')};
    box-shadow: ${(props) => (props.$highlighted ? '0px 0px 11px 0px rgb(0 0 0 / 48%)' : 'none')};
    border: ${(props) =>
      props.$highlighted ? `2px solid ${props.$color}` : '2px solid transparent'};
    border-left: none;
  }

  ${StyledPhotoDisplay} {
    img {
      width: ${(props) => (props.$highlighted ? 'auto' : '40px')};
    }

    ${StyledPhotoAlbumLink} {
      display: ${(props) => (props.$highlighted ? 'inherit' : 'none')};
    }
  }

  ${StyledSpotlightStreamItemDescription} {
    white-space: ${(props) => (props.$highlighted ? 'pre-line !important' : 'nowrap')};
    text-overflow: ${(props) => (props.$highlighted ? 'inherit' : 'ellipsis')};
  }

  ${StyledSpotlightStreamItemAge} {
    display: ${(props) => (props.$highlighted ? 'block' : 'none')};
  }

  &:hover {
    ${StyledPhotoDisplay} {
      img {
        width: auto;
      }

      ${StyledPhotoAlbumLink} {
        display: inherit;
      }
    }

    ${StyledSpotlightStreamItemDescription} {
      white-space: pre-line !important;
    }

    ${StyledSpotlightStreamItemAge} {
      display: block;
    }
  }
`;

const SPTL_ITM_IMG_DEF_SIZE = '40px';
const SPTL_ITM_IMG_FOCUS_SIZE = '128px';
export const StyledSpotlightStreamItem = styled(StyledStreamItem)`
  ${StyledPhotoItem} {
    width: ${(props) => (props.$highlighted ? SPTL_ITM_IMG_FOCUS_SIZE : SPTL_ITM_IMG_DEF_SIZE)};
    height: ${(props) => (props.$highlighted ? SPTL_ITM_IMG_FOCUS_SIZE : SPTL_ITM_IMG_DEF_SIZE)};

    img {
      width: ${(props) => (props.$highlighted ? SPTL_ITM_IMG_FOCUS_SIZE : SPTL_ITM_IMG_DEF_SIZE)};
      height: ${(props) => (props.$highlighted ? SPTL_ITM_IMG_FOCUS_SIZE : SPTL_ITM_IMG_DEF_SIZE)};
    }
  }

  &:hover {
    ${StyledPhotoItem} {
      width: ${SPTL_ITM_IMG_FOCUS_SIZE};
      height: ${SPTL_ITM_IMG_FOCUS_SIZE};

      img {
        width: ${SPTL_ITM_IMG_FOCUS_SIZE};
        height: ${SPTL_ITM_IMG_FOCUS_SIZE};
      }
    }
  }

  ${StyledPhotoAlbumLink} {
    width: ${SPTL_ITM_IMG_FOCUS_SIZE};
    height: ${SPTL_ITM_IMG_FOCUS_SIZE};
  }
`;

interface StyledStreamRowProps {
  $index: number;
}

export const StyledStreamRow = styled.div<StyledStreamRowProps>`
  position: relative;
  min-height: ${(props) => (props.theme.zoomedOut ? 96 : 124)}px;
  z-index: ${(props) => 100 + -1 * props.$index};
`;

export const StyledSpotlightStreamRow = styled(StyledStreamRow)`
  min-height: 300px;
`;

export const StyledStreamLabel = styled.div`
  padding: 4px;
  margin-top: -24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledStartDateDragger = styled.div`
  width: 10px;
  height: 10px;
  position: absolute;
  left: -7px;
  top: 4px;
  cursor: pointer;
  border-radius: 50%;
`;

interface StyledStreamProps {
  $color: string;
}

export const StyledStream = styled.div<StyledStreamProps>`
  width: 100%;
  min-height: 280px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  box-shadow: inset 0 50px 31px -30px #${(props) => tinycolor(props.$color).lighten(40).toHex()};

  ${StyledStreamItem} {
    border-left: 2px solid ${(props) => props.$color};
  }

  ${StyledStreamItemData} {
    background: #${(props) => tinycolor(props.$color).lighten(39).toHex()};
    color: ${(props) => (tinycolor(props.$color).lighten(39).isDark() ? '#fff' : '#444')};
  }

  ${StyledStreamItemBar} {
    background: ${(props) => props.$color};
  }

  ${StyledStreamLabel} {
    color: ${(props) => props.$color};
  }

  ${StyledStartDateDragger} {
    border-color: ${(props) => props.$color};
    background: #${(props) => tinycolor(props.$color).lighten(40).toHex()};
  }

  &:last-of-type {
    margin-bottom: 40px;
  }
`;

export const StyledUrlList = styled.div`
  > a {
    display: inline-block;
    margin-right: 8px;
  }
`;
