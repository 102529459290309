import {useSetRecoilState} from 'recoil';

import {searchFieldTriggerOpenAtom} from '../atoms';

/**
 */
const useActionSearchFieldTriggerOpen = (): ((setOrUnset: boolean) => void) => {
  const setFlag = useSetRecoilState(searchFieldTriggerOpenAtom);

  return (setOrUnset: boolean) => {
    setFlag(setOrUnset);
  };
};

export default useActionSearchFieldTriggerOpen;
