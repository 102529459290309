import {selector, selectorFamily} from 'recoil';

import Stream from '../domain/Stream';
import {streamsAtom} from './atoms';
import {itemsToRows} from '../data/itemsToRows';
import {activeStreamIdsAtom, selectedItemIdAtom} from './atoms.ui';
import deriveBoundaries from '../data/deriveBoundaries';
import LifeItemWithStreamProps from '../domain/LifeItemWithStreamProps';
import StreamDescription from '../domain/StreamDescription';
import Boundaries from '../domain/Boundaries';
import UserContext from '../domain/UserContext';
import {userContextAtom} from './atoms.user';
import LifeItemSearchable, {itemsToSearchableItems} from '../domain/LifeItemSearchable';
import {
  denormalizeStreamDataToItems,
  getActiveStreams,
  getAllItemsFlat,
  getItemsInYearFlat
} from '../domain/Life';

export const getUserContextOrThrow = selector<UserContext>({
  key: 'userContextSelector',
  get: ({get}) => {
    const ctx = get(userContextAtom);
    if (!ctx) {
      throw new Error('undefined UserContext!');
    }
    return ctx;
  }
});

export const getStreamsOrThrow = selector<Stream[]>({
  key: 'streamsSelector',
  get: ({get}) => {
    const streams = get(streamsAtom);
    if (!streams || !streams.length) {
      throw new Error('We expect a non-empty array of streams');
    }
    return streams;
  }
});

/**
 *
 */
const getAllItemsFlatSelector = selector<LifeItemWithStreamProps[]>({
  key: 'allItemSelector',
  get: ({get}) => {
    const streams = get(getStreamsOrThrow);
    return getAllItemsFlat(streams);
  }
});

/**
 *
 */
export const countAllItemsSelector = selector<number>({
  key: 'allItemCountSelector',
  get: ({get}) => {
    const allItems = get(getAllItemsFlatSelector);
    return allItems.length;
  }
});

/**
 * can return undefined
 */
export const getSelectedItemSelector = selector<LifeItemWithStreamProps | undefined>({
  key: 'selectedItemSelector',
  get: ({get}) => {
    const itemId = get(selectedItemIdAtom);
    if (itemId) {
      const allItems = get(getAllItemsFlatSelector);
      return allItems.find((item) => item.id === itemId);
    }
    return undefined;
  }
});

/**
 * returns a list of full stream objects that are currently "active"
 */
export const getActiveStreamsSelector = selector<Stream[]>({
  key: 'activeStreamsSelector',
  get: ({get}) => {
    const activeIds = get(activeStreamIdsAtom);
    const streams = get(streamsAtom);
    return getActiveStreams(streams, activeIds);
  }
});

/**
 * get a "rows" matrix of items for the given streamId
 */
export const getItemRowsForStreamSelector = selectorFamily<LifeItemWithStreamProps[][], string>({
  key: 'itemRowsForStreamSelector',
  get:
    (streamId) =>
    ({get}) => {
      const streams = get(streamsAtom);
      if (streams && streams.length) {
        const matchingStream = streams.find((s) => s.id === streamId);
        if (!matchingStream) {
          throw new Error(
            'this should not happen, because we expect the given streamId to be a valid one'
          );
        }
        return itemsToRows(denormalizeStreamDataToItems(matchingStream.items, matchingStream));
      } else {
        return [];
      }
    }
});

/**
 * get all items in the given year as a flat list.
 */
export const getItemsInYearFlatSelector = selectorFamily<LifeItemWithStreamProps[], number>({
  key: 'itemsInYearFlatSelector',
  get:
    (year) =>
    ({get}) =>
      getItemsInYearFlat(get(streamsAtom), year)
});

/**
 * returns a flat list of items in active streams of your life. The items in the list will contain id, title and "searchable" (which is a concatenation of title + description)
 */
export const getActiveItemSearchableSelector = selector<LifeItemSearchable[]>({
  key: 'activeItemSearchableSelector',
  get: ({get}) => {
    const allItems = get(getAllItemsFlatSelector);
    const activeStreamIds = get(activeStreamIdsAtom);
    const activeStreamItems = allItems.filter((i) => activeStreamIds.includes(i.stream));
    return itemsToSearchableItems(activeStreamItems);
  }
});

/**
 * return the streams, but just the "info" properties (id,title,color) , not including the items that belong to each stream.
 */
export const getStreamsInfoSelector = selector<StreamDescription[]>({
  key: 'streamsInfo',
  get: ({get}) => {
    const streams = get(streamsAtom);
    if (streams && streams.length) {
      return streams.map((stream) => ({
        id: stream.id,
        title: stream.title,
        color: stream.color
      }));
    } else {
      return [];
    }
  }
});

/**
 * returns Boundaries for the whole life (all items in life)
 */
export const getBoundariesSelector = selector<Boundaries>({
  key: 'boundaries',
  get: ({get}) => {
    const allItemsFlat = get(getAllItemsFlatSelector);
    return deriveBoundaries(allItemsFlat);
  }
});
