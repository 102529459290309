import StorageService from '../../../StorageService';
import PersistedLife, {getDefaultPersistedLifeToStartWith} from '../../../PersistedLife';
import envConfig from '../../../../../envConfig';
import GoogleDriveService from './GoogleDriveService';

const {loadStorageFile, saveStorageFile} = GoogleDriveService;

const lifeFileName = `${envConfig.storageFilePrefix}.json`;

const GoogleDriveStorageService: StorageService = {
  async loadPersistedLife(accessToken: string): Promise<PersistedLife> {
    let fileContent = await loadStorageFile(accessToken, lifeFileName);

    let life;
    if (!fileContent) {
      life = getDefaultPersistedLifeToStartWith();
    } else {
      life = JSON.parse(fileContent);
    }

    return life as PersistedLife;
  },

  async savePersistedLife(accessToken: string, persistedLife: PersistedLife): Promise<boolean> {
    return saveStorageFile(accessToken, lifeFileName, JSON.stringify(persistedLife));
  }
};

export default GoogleDriveStorageService;
