import {useEffect} from 'react';
import useLoadAllAlbums from '../../components/commons/useLoadAllAlbums';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {getUserContextOrThrow} from '../selectors';
import {albumListAtom} from '../atoms';

/**
 * returns an action that loads all albums and sets them to the recoil state once all albums are loaded.
 */
const useActionLoadAllAlbums = (): (() => void) => {
  const userContext = useRecoilValue(getUserContextOrThrow);
  const [startLoading, partialAlbums, allAlbumsLoaded] = useLoadAllAlbums(userContext.accessToken);
  const setAlbumList = useSetRecoilState(albumListAtom);

  useEffect(() => {
    if (allAlbumsLoaded) {
      setAlbumList(partialAlbums);
    }
  }, [partialAlbums, allAlbumsLoaded]);

  return () => {
    setAlbumList([]);
    startLoading();
  };
};

export default useActionLoadAllAlbums;
