import {useRecoilValue, useSetRecoilState} from 'recoil';

import {
  hasUnsavedItemChangesAtom,
  selectedItemIdAtom,
  SIDEBAR_CONTENT,
  sideBarContentAtom
} from '../atoms.ui';
import {streamsAtom} from '../atoms';
import {createNewItem} from '../../domain/LifeItem';
import {updateStreamsWithNewItem} from '../../data/streamsUpdateFunctions';
import {getBoundariesSelector, getStreamsOrThrow} from '../selectors';

/**
 * The returned action will create a new item at the specified "position" (days since earliest ay in life).
 * If you do not specify a streamId, the new item will be added to the first (topmost) stream.
 */
const useActionAddNewItem = (): ((itemStartDaysDelta: number, streamId?: string) => void) => {
  const boundaries = useRecoilValue(getBoundariesSelector);
  const streams = useRecoilValue(getStreamsOrThrow);
  const setStreams = useSetRecoilState(streamsAtom);
  const setSelectedItemId = useSetRecoilState(selectedItemIdAtom);
  const setSideBarContentToDisplay = useSetRecoilState(sideBarContentAtom);
  const hasUnsavedChanges = useRecoilValue(hasUnsavedItemChangesAtom);

  return (itemStartDaysDelta: number, streamId?: string): void => {
    if (hasUnsavedChanges) {
      return;
    }

    const newItemToAdd = createNewItem(
      boundaries.firstDay,
      itemStartDaysDelta,
      streamId || streams[0].id
    );
    const modifiedStreams = updateStreamsWithNewItem(streams, newItemToAdd);
    setStreams(modifiedStreams);
    setSelectedItemId(newItemToAdd.id);
    setSideBarContentToDisplay(SIDEBAR_CONTENT.ITEM);
  };
};

export default useActionAddNewItem;
