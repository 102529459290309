import React, {useState} from 'react';
import {useRecoilValue} from 'recoil';

import IconButton from '../commons/IconButton';
import {albumListAtom} from '../../state/atoms';
import Album from '../../domain/Album';
import filterAlbums from './filterAlbums';
import useActionLoadAllAlbums from '../../state/actions/loadAllAlbums';

import {
  StyledAlbumClear,
  StyledAlbumItem,
  StyledAlbumList,
  StyledAlbumListInner,
  StyledAlbumTitle,
  StyledAlbumTools
} from './_styled';
import {StyledInput} from '../commons/_styled';

interface AlbumListTypes {
  onAlbumSelected: (albumId: string) => void;
  onAlbumCleared: () => void;
}

const AlbumList = ({onAlbumSelected, onAlbumCleared}: AlbumListTypes) => {
  const albumList = useRecoilValue(albumListAtom);
  const [albumQuery, setAlbumQuery] = useState<string>('');
  const actLoadAllAlbums = useActionLoadAllAlbums();

  const filteredList = filterAlbums(albumList, albumQuery);

  return (
    <StyledAlbumList>
      <StyledAlbumTools>
        <StyledInput
          type="text"
          value={albumQuery}
          onChange={(e) => setAlbumQuery(e.target.value)}
          placeholder="Filter albums..."
        />
        <IconButton
          onClick={onRefreshAlbumList}
          className="icon-arrows-cw"
          title="Refresh albums"
        />
      </StyledAlbumTools>
      <StyledAlbumListInner>
        {albumList && (
          <StyledAlbumClear>
            <div>
              <IconButton className="icon-cancel" onClick={onAlbumCleared} />
            </div>
          </StyledAlbumClear>
        )}
        {filteredList.map((album) => (
          <AlbumItem
            key={`album:${album.id}`}
            album={album}
            onClick={() => onAlbumSelected(album.id)}
          />
        ))}
      </StyledAlbumListInner>
    </StyledAlbumList>
  );

  function onRefreshAlbumList() {
    actLoadAllAlbums();
  }
};

export default AlbumList;

interface AlbumItemTypes {
  album: Album;
  onClick: (e: React.MouseEvent) => void;
}

const AlbumItem = ({album, onClick}: AlbumItemTypes) => (
  <StyledAlbumItem onClick={onClick}>
    <img src={`${album.coverBaseUrl}=w100-h100-c`} title={album.title} alt={album.title} />
    <StyledAlbumTitle>{album.title}</StyledAlbumTitle>
  </StyledAlbumItem>
);
