export default interface UserContext {
  accessToken: string;
  expiresIn: number;
  expiresAt: number;
  email: string;
  name: string;
  userId: string;
  profileImg: string;
}

export function isUserContextStillValidInNminutes(
  ctx: UserContext | null | undefined,
  minutes: number
): boolean {
  return ctx ? ctx.expiresAt > Date.now() + minutes * 60 * 1000 : false;
}
