import React from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';

import IconButton from '../commons/IconButton';
import {currentlySavingAtom, SIDEBAR_CONTENT, sideBarContentAtom} from '../../state/atoms.ui';

import {StyledSideBarTopMenu} from './_styled';

const SideBarTopMenu = () => {
  const currentlySaving = useRecoilValue(currentlySavingAtom);
  const [sideBarContentToDisplay, setSideBarContentToDisplay] = useRecoilState(sideBarContentAtom);

  return (
    <StyledSideBarTopMenu>
      <IconButton
        className="icon-trash"
        active={sideBarContentToDisplay === SIDEBAR_CONTENT.TRASH}
        onClick={onTrashClick}
      />
      <IconButton
        className="icon-help"
        active={sideBarContentToDisplay === SIDEBAR_CONTENT.HELP}
        onClick={onHelpClick}
      />
      <IconButton
        className="icon-cog"
        active={sideBarContentToDisplay === SIDEBAR_CONTENT.SETTINGS}
        onClick={onSettingsClick}
      />

      {currentlySaving && <i className="icon-spin4 animate-spin" />}
    </StyledSideBarTopMenu>
  );

  function onHelpClick() {
    if (sideBarContentToDisplay === SIDEBAR_CONTENT.HELP) {
      setSideBarContentToDisplay(SIDEBAR_CONTENT.ITEM);
    } else {
      setSideBarContentToDisplay(SIDEBAR_CONTENT.HELP);
    }
  }

  function onSettingsClick() {
    if (sideBarContentToDisplay === SIDEBAR_CONTENT.SETTINGS) {
      setSideBarContentToDisplay(SIDEBAR_CONTENT.ITEM);
    } else {
      setSideBarContentToDisplay(SIDEBAR_CONTENT.SETTINGS);
    }
  }
  function onTrashClick() {
    if (sideBarContentToDisplay === SIDEBAR_CONTENT.TRASH) {
      setSideBarContentToDisplay(SIDEBAR_CONTENT.ITEM);
    } else {
      setSideBarContentToDisplay(SIDEBAR_CONTENT.TRASH);
    }
  }
};

export default SideBarTopMenu;
