import {useEffect} from 'react';
import {useRecoilSnapshot} from 'recoil';

export default function RecoilDebugObserver() {
  const snapshot = useRecoilSnapshot();

  useEffect(() => {
    console.group('atoms-modified');

    const recoilNodes = snapshot.getNodes_UNSTABLE({isModified: true});
    const iterator = recoilNodes[Symbol.iterator]();
    let current = iterator.next();

    while (!current.done) {
      const node = current.value;
      const ldbl = snapshot.getLoadable(node);
      if (ldbl.state === 'hasValue') {
        console.info(node.key, ldbl.contents);
      } else if (ldbl.state === 'hasError') {
        console.warn(node.key, '--has error--');
      } else if (ldbl.state === 'loading') {
        console.debug(node.key, '--is loading--');
      }

      current = iterator.next();
    }

    console.groupEnd();
  }, [snapshot]);

  return null;
}
