import {useState} from 'react';

import GooglePhotosService from '../../data/service/impl/google/GooglePhotosService';
import PartialAlbumList, {emptyAlbumList} from '../../data/service/PartialAlbumList';
import Album from '../../domain/Album';

/**
 * can load all albums sequentially (paged).
 * returns a "startLoading" function, the list of so-far loaded albums as well as a flag that indicates if all albums are loaded.
 * Will not automatically start loading. You have to invoke the returned "startLoading" function.
 */
const useLoadAllAlbums = (accessToken: string): [() => void, Album[], boolean] => {
  const [partialAlbumList, setPartialAlbumList] = useState<PartialAlbumList>(emptyAlbumList());

  return [startLoading, partialAlbumList.albums, partialAlbumList.allLoaded || false];

  function startLoading() {
    // we need to reset, when accessToken changes
    const newEmptyList = emptyAlbumList();
    setPartialAlbumList(newEmptyList);
    loadAlbumList(newEmptyList.albums);
  }

  function loadAlbumList(alreadyLoadedAlbums: Album[], nextPageToken?: string) {
    GooglePhotosService.loadAlbums(accessToken, nextPageToken)
      .then((res) => {
        const modPartialList = {...partialAlbumList};
        modPartialList.albums = alreadyLoadedAlbums.concat(res.albums);
        modPartialList.nextPageToken = res.nextPageToken;

        if (res.nextPageToken) {
          setPartialAlbumList(modPartialList);
          return loadAlbumList(modPartialList.albums, modPartialList.nextPageToken);
        } else {
          modPartialList.allLoaded = true;
          setPartialAlbumList(modPartialList);
        }
      })
      .catch((err) => console.error(err));
  }
};

export default useLoadAllAlbums;
