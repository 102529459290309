import {useRecoilValue, useSetRecoilState} from 'recoil';

import {streamsAtom} from '../atoms';
import {activeStreamIdsAtom} from '../atoms.ui';
import {updateStreamsDeleteStream} from '../../data/streamsUpdateFunctions';
import {getStreamsOrThrow} from '../selectors';

const useActionDeleteStream = (): ((streamIdToDelete: string) => void) => {
  const streams = useRecoilValue(getStreamsOrThrow);
  const setStreams = useSetRecoilState(streamsAtom);
  const setActiveStreamIds = useSetRecoilState(activeStreamIdsAtom);

  return (streamIdToDelete: string): void => {
    const modifiedStreams = updateStreamsDeleteStream(streams, streamIdToDelete);
    setStreams(modifiedStreams);
    setActiveStreamIds(modifiedStreams.map((s) => s.id)); // to make it easy, just set all available streams as "active"
  };
};

export default useActionDeleteStream;
