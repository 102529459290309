import React from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {format} from 'date-fns';

import {userContextAtom} from '../../state/atoms.user';
import ctxPersistorService from '../../data/service/impl/LSContextPersistorService';
import Button from '../commons/Button';
import {getUserContextOrThrow} from '../../state/selectors';

import {StyledUserProfile} from './_styled';

const UserProfile = () => {
  const userContext = useRecoilValue(getUserContextOrThrow);
  const setUserContext = useSetRecoilState(userContextAtom);
  return (
    <StyledUserProfile x-user-id={userContext.userId}>
      <h3>User</h3>

      <div>
        <img src={userContext.profileImg} alt={userContext.userId} />
      </div>
      <div>{userContext.name}</div>
      <div>{userContext.email}</div>

      <div>Session expires at {format(new Date(userContext.expiresAt), 'HH:mm')}</div>
      <Button onClick={onLogoutButtonClick}>Logout</Button>
    </StyledUserProfile>
  );

  function onLogoutButtonClick() {
    ctxPersistorService.persistContext(undefined);
    setUserContext(undefined);
  }
};
export default UserProfile;
