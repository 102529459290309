import {atom} from 'recoil';

/**
 * Flag that indicates whether the app is currently saving it's state to the persistent storage
 */
export const currentlySavingAtom = atom<boolean>({
  key: 'currentlySaving',
  default: false
});

/**
 * Flag that indicates that a item has unsaved changes (and thus we prevent switching to another item)
 */
export const hasUnsavedItemChangesAtom = atom<boolean>({
  key: 'hasUnsavedItemChanges',
  default: false
});

/**
 * Flag that indicates whether the "year grid" (vertical gray lines) are currently visible
 */
export const gridVisibleAtom = atom<boolean>({
  key: 'gridVisible',
  default: true
});

export enum SIDEBAR_CONTENT {
  ITEM = 'ITEM',
  SETTINGS = 'SETTINGS',
  HELP = 'HELP',
  TRASH = 'TRASH',
  ERROR = 'ERROR'
}

/**
 * Indicates what should be displayed in the sidebar
 *
 * - the currently selected item
 * - "settings" (contains streams editor and statistics) are currently visible
 * - "help" (contains info on how to use and features)
 * - error
 */
export const sideBarContentAtom = atom<SIDEBAR_CONTENT>({
  key: 'sideBarContent',
  default: SIDEBAR_CONTENT.ITEM
});

/**
 * Flag that indicates whether the "zoomed out" mode of you life is currently on
 */
export const zoomedOutModeAtom = atom<boolean>({
  key: 'zoomedOutMode',
  default: false
});

/**
 * If set to a number > 0, "year spotlight mode" is on. All item of this one year are displayed in one stream
 */
export const spotlightYearAtom = atom<number>({
  key: 'spotlightYear',
  default: 0
});

/**
 * list of active (visible) stream ids.
 */
export const activeStreamIdsAtom = atom<string[]>({
  key: 'activeStreamIds',
  default: []
});

/**
 * The id of the currently "selected" item
 */
export const selectedItemIdAtom = atom<string | undefined>({
  key: 'selectedItemId',
  default: undefined
});

/**
 * We store the horizontal scroll position in "days after first day of time span",
 * so that we know "where" (i.e. when on the timeline) we need to create a new item, if the user clicks the "add item" button.
 *
 * this value will represent the day "in the center of the screen".
 */
export const lifeHorizontalPosAtom = atom<number>({
  key: 'lifeHorizontalPos',
  default: 0
});

/**
 * number that if set to something larger than zero (and a year within our life span), the Life.tsx Component will scroll to that year.
 */
export const jumpToYearAtom = atom<number>({
  key: 'jumpToYear',
  default: 0,
  effects: [
    ({onSet, resetSelf}) => {
      onSet(() => {
        setTimeout(() => resetSelf(), 100); // reset ourselves right after it was set
      });
    }
  ]
});

/**
 * boolean flag that indiciates whether the "jump to year" input field should currently be displayed
 */
export const jumpToYearInputVisibleAtom = atom<boolean>({
  key: 'jumpToYearVisible',
  default: false
});
