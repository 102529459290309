import {useRecoilValue} from 'recoil';
import {userContextAtom} from '../state/atoms.user';
import {MutableRefObject, useEffect, useRef} from 'react';

interface UserContextExpirationObserverTypes {
  nSecondsBeforeExpiry: number; // how many seconds before the accessToken expires should we invoke the "onCtxWillShortlyExpire" cb.
  onCtxWillShortlyExpire: () => void;
}

const UserContextExpirationObserver = ({
  nSecondsBeforeExpiry,
  onCtxWillShortlyExpire
}: UserContextExpirationObserverTypes) => {
  const userContext = useRecoilValue(userContextAtom);
  const timeoutIdRef = useRef() as MutableRefObject<number>;

  useEffect(() => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }

    if (userContext) {
      const timeoutMs = Math.max((userContext.expiresIn - nSecondsBeforeExpiry) * 1000, 0);
      timeoutIdRef.current = window.setTimeout(() => void onCtxWillShortlyExpire(), timeoutMs);
    } else if (timeoutIdRef.current) {
      timeoutIdRef.current = 0;
    }
  }, [userContext, nSecondsBeforeExpiry]);

  return null;
};

export default UserContextExpirationObserver;
