import Stream from '../../domain/Stream';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {streamsAtom} from '../atoms';
import {activeStreamIdsAtom, selectedItemIdAtom} from '../atoms.ui';
import {findNewestItem} from '../../domain/Life';

const useActionSetStreams = (): ((streamsToSave: Stream[]) => void) => {
  const setStreams = useSetRecoilState(streamsAtom);
  const setActiveStreamIds = useSetRecoilState(activeStreamIdsAtom);
  const [selectedId, setSelectedId] = useRecoilState(selectedItemIdAtom);

  return (streamsToSave: Stream[]): void => {
    setStreams(streamsToSave);
    setActiveStreamIds(streamsToSave.map((s) => s.id)); // to make it easy, just set all available streams as "active"
    if (!selectedId) {
      setSelectedId(findNewestItem(streamsToSave).id);
    }
  };
};

export default useActionSetStreams;
