import DatePicker from 'react-datepicker';
import React, {useEffect, useState} from 'react';
import {isBefore} from 'date-fns';
import {useRecoilValue} from 'recoil';

import PartialPhotoList, {emptyPhotoList} from '../../data/service/PartialPhotoList';
import GooglePhotosService from '../../data/service/impl/google/GooglePhotosService';
import {getUserContextOrThrow} from '../../state/selectors';
import Spinner from '../commons/Spinner';
import {StyledPhotoDisplay, StyledPhotoDisplayInner, StyledPhotoItem} from '../commons/_styled';
import {StyledAlbumClear, StyledDateInputWrapper} from './_styled';
import IconButton from '../commons/IconButton';

interface SinglePhotoPickerTypes {
  preselectDate: Date;
  onSinglePhotoSelected: (photoId: string) => void;
  onSinglePhotoCleared: () => void;
}

const SinglePhotoPicker = ({
  preselectDate,
  onSinglePhotoSelected,
  onSinglePhotoCleared
}: SinglePhotoPickerTypes) => {
  const userContext = useRecoilValue(getUserContextOrThrow);
  const [photoDate, setPhotoDate] = useState<Date>(preselectDate);

  const [partialPhotoList, setPartialPhotoList] = useState(emptyPhotoList());

  useEffect(() => {
    setPartialPhotoList(emptyPhotoList());
    doLoad(undefined, emptyPhotoList());
  }, [photoDate]);

  useEffect(() => {
    setPhotoDate(preselectDate);
  }, [preselectDate]);

  const isInThePast = (date: Date) => {
    return isBefore(date, new Date());
  };

  function doLoad(nextPageToken: string | undefined, preList: PartialPhotoList) {
    GooglePhotosService.loadPhotosFromDate(userContext.accessToken, photoDate, nextPageToken).then(
      (pl) => {
        const modifiedPl: PartialPhotoList = {
          photos: [...preList.photos, ...pl.photos],
          nextPageToken: pl.nextPageToken
        };

        if (pl.nextPageToken) {
          setPartialPhotoList(modifiedPl);
          doLoad(modifiedPl.nextPageToken, modifiedPl);
        } else {
          modifiedPl.allLoaded = true;
          setPartialPhotoList(modifiedPl);
        }
      }
    );
  }

  return (
    <div>
      <StyledDateInputWrapper>
        <DatePicker
          calendarStartDay={1}
          dateFormat="dd.MM.yyyy"
          filterDate={isInThePast}
          onChange={(newPhotoDate) => {
            if (newPhotoDate) {
              setPhotoDate(newPhotoDate);
            }
          }}
          placeholderText="Start Date"
          selected={photoDate}
        />
      </StyledDateInputWrapper>

      <div>
        <StyledPhotoDisplay>
          <StyledPhotoDisplayInner>
            <StyledAlbumClear>
              <div>
                <IconButton className="icon-cancel" onClick={onSinglePhotoCleared} />
              </div>
            </StyledAlbumClear>

            {partialPhotoList &&
              partialPhotoList.photos.length > 0 &&
              partialPhotoList.photos.map((photo, pIndex) => (
                <StyledPhotoItem
                  key={`photo:${photo.id}:${pIndex}`}
                  onClick={() => onSinglePhotoSelected(photo.id)}
                >
                  <img src={`${photo.baseUrl}=h128-w128-c`} alt={`${pIndex}`} />
                </StyledPhotoItem>
              ))}
            {(!partialPhotoList || !partialPhotoList.allLoaded) && <Spinner />}
          </StyledPhotoDisplayInner>
        </StyledPhotoDisplay>
      </div>
    </div>
  );
};

export default SinglePhotoPicker;
