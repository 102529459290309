import React from 'react';

import {StyledFavIcon} from './_styled';

interface FavIconTypes {
  url: string;
}

const FavIcon = ({url}: FavIconTypes) => {
  try {
    const urlObject = new URL(url);
    const faviconSrc = `https://www.google.com/s2/favicons?domain=${urlObject.origin}`;
    return (
      <StyledFavIcon>
        <img src={faviconSrc} alt={`the Favicon of ${urlObject.origin}`} />
      </StyledFavIcon>
    );
  } catch (e) {
    return <span>{url}</span>;
  }
};

export default FavIcon;
