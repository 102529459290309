import React from 'react';

import {StyledSpinner} from './_styled';

const Spinner = () => (
  <StyledSpinner>
    <i className="icon-spin4 animate-spin" />
  </StyledSpinner>
);

export default Spinner;
