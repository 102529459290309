import React from 'react';
import {useRecoilValue} from 'recoil';

import {getStreamsInfoSelector} from '../state/selectors';
import {activeStreamIdsAtom} from '../state/atoms.ui';
import useActionToggleActiveStream from '../state/actions/setActiveStreams';

import {StyledStreamsFilter, StyledStreamToggleButton} from './_styled';

const StreamsFilter = () => {
  const actToggleActiveStream = useActionToggleActiveStream();
  const streams = useRecoilValue(getStreamsInfoSelector);
  const activeStreamIds = useRecoilValue(activeStreamIdsAtom);

  return (
    <StyledStreamsFilter>
      {streams &&
        streams.length &&
        streams.map((stream) => (
          <StyledStreamToggleButton
            key={`stream-filter:${stream.id}`}
            title={stream.title}
            onClick={() => actToggleActiveStream(stream.id)}
            $color={stream.color}
            $selected={activeStreamIds.includes(stream.id)}
          />
        ))}
    </StyledStreamsFilter>
  );
};

export default StreamsFilter;
