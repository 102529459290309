import {atom} from 'recoil';

import UserContext from '../domain/UserContext';

/**
 * will contain "accessToken" after successful login to google account.
 * {
 *   accessToken: 'klgksöldgksöldgksöldgköslkgösdlg'
 * }
 */
export const userContextAtom = atom<UserContext | undefined>({
  key: 'userContext',
  default: undefined
});
