import React from 'react';
import LifeItem from '../../domain/LifeItem';
import {StyledTrashBinItemList, StyledTrashBinItemListElement} from './_styled';
import {formatDateNumeric} from '../../data/timeUtil';
import IconButton from '../commons/IconButton';

interface TrashTypes {
  trashedItems: LifeItem[];
  onRestoreItem: (item: LifeItem) => void;
}

const Trash = ({trashedItems, onRestoreItem}: TrashTypes) => {
  return (
    <div>
      <h2>Trash Bin</h2>

      {trashedItems.length < 1 && <p>There are no items in the trash bin.</p>}

      <StyledTrashBinItemList>
        {trashedItems.map((it) => (
          <TrashItem key={it.id} onRestoreItem={onRestoreItem} item={it} />
        ))}
      </StyledTrashBinItemList>

      <p>
        <b>
          Please note: the trash bin is not persistent. If you refresh or close the browser window,
          trashed items are gone for good!
        </b>
      </p>
    </div>
  );
};

export default Trash;

interface TrashItemTypes {
  item: LifeItem;
  onRestoreItem: (item: LifeItem) => void;
}

const TrashItem = ({item, onRestoreItem}: TrashItemTypes) => {
  const startFormatted = formatDateNumeric(item.startDateObj);
  const endFormatted = item.endDateObj ? ` - ${formatDateNumeric(item.endDateObj)}` : '';
  return (
    <StyledTrashBinItemListElement>
      <span>{item.title}</span>
      <span>
        {startFormatted} {endFormatted}
      </span>
      <IconButton
        onClick={() => onRestoreItem(item)}
        title={`Restore "${item.title}"`}
        className="icon-level-up"
      />
    </StyledTrashBinItemListElement>
  );
};
