import React, {useEffect, useState} from 'react';
import {useRecoilValue} from 'recoil';

import {getUserContextOrThrow} from '../../state/selectors';
import GooglePhotosService from '../../data/service/impl/google/GooglePhotosService';

interface GoogleSinglePhotoTypes {
  photoId: string;
  urlPostfix?: string;
}

const GoogleSinglePhoto = ({photoId, urlPostfix}: GoogleSinglePhotoTypes) => {
  const userContext = useRecoilValue(getUserContextOrThrow);
  const [baseUrl, setBaseUrl] = useState('');

  useEffect(() => {
    setBaseUrl('');
    GooglePhotosService.loadPhotoInfo(userContext.accessToken, photoId).then((r) =>
      setBaseUrl(r.baseUrl)
    );
  }, [photoId]);

  if (!baseUrl) {
    return (
      <div>
        <i className="icon icon-hourglass-1" />
      </div>
    );
  }

  return <img alt={photoId} src={`${baseUrl}${urlPostfix}`} />;
};

export default GoogleSinglePhoto;
