import React from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';

import {gridVisibleAtom, spotlightYearAtom} from '../state/atoms.ui';
import RoundIconButton from './commons/RoundIconButton';

import {StyledOptionsBar, StyledShadowedPill} from './_styled';
import useActionSetSpotlightYear from '../state/actions/setSpotlightYear';

const SpotlightOptionsBar = () => {
  const [gridVisible, setGridVisible] = useRecoilState(gridVisibleAtom);
  const spotlightYear = useRecoilValue(spotlightYearAtom);
  const actSetSpotlightYear = useActionSetSpotlightYear();

  return (
    <StyledOptionsBar>
      <RoundIconButton
        title="Show Year Lines"
        className="icon-table"
        onClick={() => setGridVisible(!gridVisible)}
        active={gridVisible}
      />

      <RoundIconButton
        title={`Go to ${spotlightYear - 1}`}
        className="icon-left-open"
        onClick={() => actSetSpotlightYear(spotlightYear - 1)}
        active={true}
      />

      <StyledShadowedPill>{spotlightYear}</StyledShadowedPill>

      <RoundIconButton
        title={`Go to ${spotlightYear + 1}`}
        className="icon-right-open"
        onClick={() => actSetSpotlightYear(spotlightYear + 1)}
        active={true}
      />

      <RoundIconButton
        title="Exit Spotlight"
        className="icon-cancel"
        onClick={() => actSetSpotlightYear(0)}
        active={true}
      />
    </StyledOptionsBar>
  );
};

export default SpotlightOptionsBar;
