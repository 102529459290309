import {
  isDate,
  format,
  set,
  intervalToDuration,
  formatDuration,
  isSameDay,
  isBefore,
  isAfter
} from 'date-fns';
import {de, enUS} from 'date-fns/locale';

export function formatYearOnly(dateOrTs: Date | number) {
  return format(normalizeDateOrTs(dateOrTs), 'yyyy', {locale: de});
}

export function formatDateNumeric(dateOrTs: Date | number) {
  return format(normalizeDateOrTs(dateOrTs), 'dd.MM.yyyy', {locale: de});
}

export function formatRange(startDate: Date, endDate: Date) {
  return formatDuration(
    intervalToDuration({
      start: startDate,
      end: endDate
    }),
    {format: ['years', 'months', 'days'], locale: enUS}
  );
}

export function getAgeString(birth: Date, startDate: Date) {
  const alreadyBorn = isBeforeDate(birth, startDate);
  const isSameDayAsBirth = isSameDay(birth, startDate);
  const isFuture = isAfterDate(startDate, new Date());

  if (isSameDayAsBirth) {
    return `On the day you were born!`;
  } else if (isFuture) {
    return `You will be ${formatAge(birth, startDate)} old`;
  } else if (alreadyBorn) {
    return `You were ${formatAge(birth, startDate)} old`;
  } else {
    return `${formatAge(startDate, birth)} before you were born`;
  }
}

function formatAge(startDate: Date, endDate: Date) {
  const duration = intervalToDuration({
    start: startDate,
    end: endDate
  });

  if (!duration.months && !duration.years) {
    return formatDuration(duration, {format: ['days'], locale: enUS});
  }

  return formatDuration(duration, {format: ['years', 'months'], locale: enUS, delimiter: ' and '});
}

export function normalizeDateOrTs(dateOrTs: Date | number) {
  if (isDate(dateOrTs)) {
    return dateOrTs;
  }

  return new Date(dateOrTs * 1000);
}

export function resetTime(dateObject: Date) {
  return set(dateObject, {
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0
  });
}

export function isBeforeDate(dateOrTsA: Date | number, dateOrTsB: Date | number) {
  return isBefore(resetTime(normalizeDateOrTs(dateOrTsA)), resetTime(normalizeDateOrTs(dateOrTsB)));
}

export function isAfterDate(dateOrTsA: Date | number, dateOrTsB: Date | number) {
  return isAfter(resetTime(normalizeDateOrTs(dateOrTsA)), resetTime(normalizeDateOrTs(dateOrTsB)));
}

export function toTsSeconds(date: Date) {
  return Math.floor(date.getTime() / 1000);
}
