import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';

import {findNewestItem, getActiveStreams} from '../../domain/Life';
import Stream from '../../domain/Stream';
import {activeStreamIdsAtom, selectedItemIdAtom} from '../atoms.ui';
import {getSelectedItemSelector, getStreamsOrThrow} from '../selectors';

/**
 * The returned action will correctly set the active stream ids in the recoil state, given a "toggled" streamId.
 */
const useActionToggleActiveStream = (): ((toggledStreamId: string) => void) => {
  const allStreams = useRecoilValue(getStreamsOrThrow);
  const [currentlyActiveStreamIds, setActiveStreamIds] = useRecoilState(activeStreamIdsAtom);
  const selectedItem = useRecoilValue(getSelectedItemSelector);
  const setSelectedItemId = useSetRecoilState(selectedItemIdAtom);

  return (toggledStreamId: string): void => {
    const activeStreamIds = selectCorrectStreams(
      allStreams,
      currentlyActiveStreamIds,
      toggledStreamId
    );
    setActiveStreamIds(activeStreamIds);

    // make sure that the currently selected item is within the now active streams
    if (selectedItem) {
      const isSelectedItemInActiveStreams = activeStreamIds.includes(selectedItem.stream);
      if (!isSelectedItemInActiveStreams) {
        setSelectedItemId(getNewestItemOfActiveStreams());
      }
    } else {
      // if no item is currently selected, select the newest item in the active streams
      setSelectedItemId(getNewestItemOfActiveStreams());
    }

    function getNewestItemOfActiveStreams() {
      const activeStreams = getActiveStreams(allStreams, activeStreamIds);
      return findNewestItem(activeStreams).id;
    }
  };
};

const selectCorrectStreams = (
  allStreams: Stream[],
  currentlyActiveStreamIds: string[],
  toggledStreamId: string
): string[] => {
  if (currentlyActiveStreamIds.length === 1 && currentlyActiveStreamIds[0] === toggledStreamId) {
    // the last active stream is about to be deselected -> let's select all streams
    return allStreams.map((s) => s.id);
  } else if (currentlyActiveStreamIds.includes(toggledStreamId)) {
    // there is already a selection of active/non-active streams, one active stream gets clicked -> let's deselect the clicked one
    return currentlyActiveStreamIds.filter((aSi) => aSi !== toggledStreamId);
  } else {
    // there is already a selection of active/non-active streams, one not-yet-active stream gets clicked -> let's select the clicked one
    return [...currentlyActiveStreamIds, toggledStreamId];
  }
};

export default useActionToggleActiveStream;
