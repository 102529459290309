import Album from '../../domain/Album';

/**
 * filters the given list of albums. returns an array of albums where the album title contains the given query.
 * (case insensitiv)
 */
export default function filterAlbums(albums: Album[] | undefined, query: string): Album[] {
  if (!albums) {
    return [];
  }
  if (!query) {
    return albums;
  }

  const queryRegex = new RegExp(query, 'gi');
  return albums.filter((a) => (a.title || '').search(queryRegex) > -1);
}
