import {useEffect} from 'react';
import Mousetrap from 'mousetrap';

import useActionAddNewItem from '../state/actions/addNewItem';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {
  jumpToYearInputVisibleAtom,
  lifeHorizontalPosAtom,
  spotlightYearAtom
} from '../state/atoms.ui';
import useActionSetSpotlightYear from '../state/actions/setSpotlightYear';
import useActionSearchFieldTriggerOpen from '../state/actions/searchFieldTriggerOpen';

/**
 * Using "mousetrap" to listen to keyboard events and triggering some actions
 *
 * see https://craig.is/killing/mice  for the library "mousetrap"
 */
const KeyboardShortcutsListener = () => {
  const horizontalPos = useRecoilValue(lifeHorizontalPosAtom);
  const spotlightYear = useRecoilValue(spotlightYearAtom);
  const setJumpToYearInputVisible = useSetRecoilState(jumpToYearInputVisibleAtom);
  const actAddNewItem = useActionAddNewItem();
  const actTrigger = useActionSearchFieldTriggerOpen();
  const actSetSpotlightYear = useActionSetSpotlightYear();

  useEffect(() => {
    register();
    return () => unregister();
  }, [horizontalPos, actAddNewItem, spotlightYear]);

  /*
  register all our keyboard shortcuts
   */
  function register() {
    Mousetrap.bind('esc', () => actSetSpotlightYear(0));
    Mousetrap.bind('n', () => {
      // like "n" = "new"
      actAddNewItem(horizontalPos);
      return false; // by returning false, we prevent the letter "n" from appearing in the new item's title input field
    });
    Mousetrap.bind('ctrl+right', () => {
      if (spotlightYear > 0) actSetSpotlightYear(spotlightYear + 1);
    });
    Mousetrap.bind('ctrl+left', () => {
      if (spotlightYear > 0) actSetSpotlightYear(spotlightYear - 1);
    });
    Mousetrap.bind('j', () => setJumpToYearInputVisible(true)); // like "J" = "Jahr"
    Mousetrap.bind('g', () => setJumpToYearInputVisible(true)); // like "g" = "Go"  = "go to the year"
    Mousetrap.bind('s', (a) => {
      // like "s" = "Search"
      a.preventDefault();
      actTrigger(true);
      return false;
    });
  }

  /*
   Clear all our keyboard shortcuts
   */
  function unregister() {
    Mousetrap.reset();
  }

  return null;
};

export default KeyboardShortcutsListener;
