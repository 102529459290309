import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';

import {streamsAtom, trashedItemsAtom} from '../atoms';
import {selectedItemIdAtom, SIDEBAR_CONTENT, sideBarContentAtom} from '../atoms.ui';
import {updateStreamsWithNewItem} from '../../data/streamsUpdateFunctions';
import {getStreamsOrThrow} from '../selectors';
import LifeItem from '../../domain/LifeItem';
import {updateTrashedRemoveItem} from '../../data/trashUpdateFunctions';

/**
 *
 */
const useActionRestoreItem = (): ((itemToRestore: LifeItem) => void) => {
  const streams = useRecoilValue(getStreamsOrThrow);
  const setStreams = useSetRecoilState(streamsAtom);
  const setSelectedItemId = useSetRecoilState(selectedItemIdAtom);
  const setSideBarContent = useSetRecoilState(sideBarContentAtom);
  const [trashedItems, setTrashedItems] = useRecoilState(trashedItemsAtom);

  return (itemToRestore: LifeItem): void => {
    // remove the item from the trash
    const modifiedTrashedItems = updateTrashedRemoveItem(trashedItems, itemToRestore);
    setTrashedItems(modifiedTrashedItems);

    // If the stream of this item was deleted in the meantime, this "updateStreamsWithNewItem" method will restore the item to the first stream
    const modifiedStreams = updateStreamsWithNewItem(streams, itemToRestore);
    setStreams(modifiedStreams);

    setSelectedItemId(itemToRestore.id);
    setSideBarContent(SIDEBAR_CONTENT.ITEM);
  };
};

export default useActionRestoreItem;
