import {getDaysInYear} from 'date-fns';

export default interface YearInfo {
  year: number;
  daysInYear: number;
}

export const getYearInfoForSpan = (yearSpan: number, firstYear: number): YearInfo[] =>
  new Array(yearSpan)
    .fill(1)
    .map((y, index) => firstYear + index)
    .map((y) => ({
      year: y,
      daysInYear: getDaysInYear(new Date(y, 7, 14))
    }));
