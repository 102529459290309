import React, {useEffect, useRef} from 'react';
import {getYear} from 'date-fns';
import {useRecoilValue} from 'recoil';

import {selectedItemIdAtom} from '../../../state/atoms.ui';
import {birthAtom} from '../../../state/atoms';
import {formatDateNumeric, getAgeString} from '../../../data/timeUtil';
import {
  useCalculateSpotlightStreamItemLeftOffset,
  useCalculateSpotlightStreamItemBarWidth
} from '../spotlightLayouter';
import PhotoDisplay from '../../commons/PhotoDisplay';
import FavIcon from '../../commons/FavIcon';
import LifeItemWithStreamProps from '../../../domain/LifeItemWithStreamProps';

import {
  StyledStreamItemBar,
  StyledSpotlightStreamItemData,
  StyledStreamItemTitle,
  StyledSpotlightStreamItemDescription,
  StyledSpotlightStreamItem,
  StyledSpotlightStreamItemAge,
  StyledUrlList
} from './_styled';
import {StyledPhotoDisplay, StyledPhotoDisplayInner, StyledPhotoItem} from '../../commons/_styled';
import GoogleSinglePhoto from '../../commons/GoogleSinglePhoto';

interface SpotlightStreamItemTypes {
  item: LifeItemWithStreamProps;
  onItemClick: () => void;
}

const SpotlightStreamItem = ({item, onItemClick}: SpotlightStreamItemTypes) => {
  const birth = useRecoilValue(birthAtom);
  const selectedItemId = useRecoilValue(selectedItemIdAtom);
  const spotlightYear = getYear(item.startDateObj);
  const streamItemRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  /*
   * Removed "scroll into view" behaviour when item is selected by user, here in the year spotlight view.
   * It somewhat felt "strange" and unexpected. - it still makes sense for the default, "Life", view where the user can e.g. search for an item via the Options bar
   *
   * However, when the year changes - the first item in the spotlight year is selected (see respective action /state/actions/setSpotlightYear.ts).
   * Let's scroll to the first item then.
   */
  useEffect(() => {
    if (item.id === selectedItemId && streamItemRef.current) {
      setTimeout(
        () =>
          //https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
          streamItemRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center', // Defines vertical alignment. One of start, center, end, or nearest. Defaults to start.
            inline: 'center' // Defines horizontal alignment. One of start, center, end, or nearest. Defaults to nearest.
          }),
        10
      );
    }
  }, [spotlightYear]);

  const startFormatted = formatDateNumeric(item.startDateObj);
  const endFormatted = item.endDateObj ? formatDateNumeric(item.endDateObj) : '';

  const leftOffset = useCalculateSpotlightStreamItemLeftOffset(item);
  const streamItemBarWidth = useCalculateSpotlightStreamItemBarWidth(item);

  const googleMapPlaceUrl = item.place
    ? `https://www.google.com/maps/place/${item.place.location.lat},${item.place.location.lng}`
    : undefined;

  return (
    <StyledSpotlightStreamItem
      title={item.title}
      $color={item.streamColor}
      $left={leftOffset}
      $highlighted={selectedItemId === item.id}
    >
      <StyledStreamItemBar
        style={{width: `${streamItemBarWidth}px`}}
        onClick={onItemDataClick}
        $color={item.streamColor}
      />
      <StyledSpotlightStreamItemData ref={streamItemRef} onClick={onItemDataClick}>
        <StyledStreamItemTitle>
          <span>{item.title}</span>
        </StyledStreamItemTitle>
        <StyledSpotlightStreamItemDescription>
          {item.description}
        </StyledSpotlightStreamItemDescription>

        {item.album && <PhotoDisplay albumId={item.album} />}

        {item.photo && (
          <StyledPhotoDisplay>
            <StyledPhotoDisplayInner>
              <StyledPhotoItem>
                <GoogleSinglePhoto photoId={item.photo} urlPostfix="=h128-w128-c" />
              </StyledPhotoItem>
            </StyledPhotoDisplayInner>
          </StyledPhotoDisplay>
        )}

        {item.place && (
          <a href={googleMapPlaceUrl} target="_blank" rel="noopener noreferrer">
            <FavIcon url="https://maps.google.com" /> {item.place.name}
          </a>
        )}

        <StyledUrlList>
          {item.urls.map((url, i) => (
            <a
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              key={`item:${item.id}:url:${i}`}
              title={url}
            >
              <FavIcon url={url} />
            </a>
          ))}
        </StyledUrlList>

        <div>
          {startFormatted}
          {endFormatted && ` - ${endFormatted}`}, {item.streamTitle}
          <StyledSpotlightStreamItemAge>
            {getAgeString(birth, item.startDateObj)}
          </StyledSpotlightStreamItemAge>
        </div>
      </StyledSpotlightStreamItemData>
    </StyledSpotlightStreamItem>
  );

  function onItemDataClick(e: React.MouseEvent) {
    e.stopPropagation(); // stop bubbling, so that "stream" does not register a click, otherwise will "deselect"
    onItemClick();
  }
};

export default SpotlightStreamItem;
