import {format, getDaysInMonth} from 'date-fns';

export default interface MonthInfo {
  year: number; // the year as integer of format YYYY     e.g 2003
  month: string; // the month as string. e.g. "January 2002"
  daysCount: number; // number of days in this month. e.g.  31 for January.   30 for April, ...
}

/**
 * includes
 * - december of previous year
 * - all months of spotlight year
 * - january of next year
 */
export const getMonthsInfoForSpotlightYear = (spotlightYear: number): MonthInfo[] => [
  getMonthsInfoForDecemberPrevYear(spotlightYear),
  ...getMonthsInfoForWholeYear(spotlightYear),
  getMonthsInfoForJanuaryNextYear(spotlightYear)
];

const getMonthsInfoForWholeYear = (year: number): MonthInfo[] =>
  new Array(12).fill(true).map((v: boolean, i) => {
    const firstOfMonthDate = new Date(year, i, 1);
    return {
      year,
      month: format(firstOfMonthDate, 'LLLL yyyy'),
      daysCount: getDaysInMonth(firstOfMonthDate)
    };
  });

/**
 * Will return a "MonthInfo" for January of the following (!) year
 */
const getMonthsInfoForJanuaryNextYear = (year: number): MonthInfo => {
  const firstOfJanuary = new Date(year + 1, 0, 1);
  return {
    year: year + 1,
    month: format(firstOfJanuary, 'LLLL yyyy'),
    daysCount: getDaysInMonth(firstOfJanuary)
  };
};

/**
 * Will return a "MonthInfo" for December of the previous (!) year
 */
const getMonthsInfoForDecemberPrevYear = (year: number): MonthInfo => {
  const firstOfDecember = new Date(year - 1, 11, 1);
  return {
    year: year - 1,
    month: format(firstOfDecember, 'LLLL yyyy'),
    daysCount: getDaysInMonth(firstOfDecember)
  };
};
