import React from 'react';

export interface RightMouseDragRef {
  element: HTMLDivElement;
  onMouseDown: (e: MouseEvent) => void;
  onMouseUp: (e: MouseEvent) => void;
  onMouseMove: (e: MouseEvent) => void;
  prevent: (e: MouseEvent) => void;
  dragging?: boolean;
}

/**
 * Registers mouse-event listeners on the given HTMLDivElement.
 * On right-button drag, we set the "scrollLeft" property on the given element accordingly.
 * This results in "horizontal scrolling" of the given element.
 *
 * This callback is called twice, if a new ref is set for our dom element. (once with "null", once with the new element)
 *
 * We use a React ref (useRef()) as container for our state. see https://reactjs.org/docs/hooks-faq.html#is-there-something-like-instance-variables
 */
const rightMouseDragRefCb = (
  element: HTMLDivElement | null,
  refInstance: React.MutableRefObject<RightMouseDragRef>,
  onDragend?: (element: HTMLDivElement) => void
) => {
  if (element) {
    refInstance.current = {
      element, // store the current, new element in our (instance) state
      onMouseDown: (e) => {
        if (e.button !== 2) {
          return;
        }
        refInstance.current.dragging = true;
      },
      onMouseUp: () => {
        if (onDragend && refInstance.current.dragging) {
          onDragend(element);
        }
        refInstance.current.dragging = false;
      },
      onMouseMove: (e) => {
        if (!refInstance.current.dragging) {
          return;
        }

        element.scrollLeft -= e.movementX;
      },
      prevent: (e) => {
        e.preventDefault();
      }
    };

    // add our event listeners to the element
    element.addEventListener('mousedown', refInstance.current.onMouseDown);
    element.addEventListener('mouseup', refInstance.current.onMouseUp);
    element.addEventListener('mouseleave', refInstance.current.onMouseUp);
    element.addEventListener('mousemove', refInstance.current.onMouseMove);
    element.addEventListener('contextmenu', refInstance.current.prevent);
  } else {
    // element is null. make sure to remove event listeners, if we did attach them before
    if (refInstance.current && refInstance.current.element) {
      refInstance.current.element.removeEventListener('mousedown', refInstance.current.onMouseDown);
      refInstance.current.element.removeEventListener('mouseup', refInstance.current.onMouseUp);
      refInstance.current.element.removeEventListener('mouseleave', refInstance.current.onMouseUp);
      refInstance.current.element.removeEventListener('mousemove', refInstance.current.onMouseMove);
      refInstance.current.element.removeEventListener('contextmenu', refInstance.current.prevent);
    }
  }
};

export default rightMouseDragRefCb;
