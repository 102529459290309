/**
 *
 * @param response
 */
export async function handleFetchResponseErrors(response: Response) {
  if (response.ok) {
    return response;
  }

  if (response.json) {
    const body = await response.json();
    // console.error(body);
    throw new Error(`${response.status} : ${response.statusText} : ${JSON.stringify(body)}`);
  } else {
    throw new Error(`${response.status} : ${response.statusText}`);
  }
}
