import React from 'react';
import {useRecoilValue} from 'recoil';

import {zoomedOutModeAtom} from '../../state/atoms.ui';
import {calculateYearGridColumn, useCalculateTimeLineWidth} from './layouter';
import Boundaries from '../../domain/Boundaries';

import {StyledTimeLine, StyledYearMarker, StyledYearMarkerText} from './_styled';
import useActionSetSpotlightYear from '../../state/actions/setSpotlightYear';

interface YearsTimeLineTypes {
  boundaries: Boundaries;
}

const YearsTimeLine = ({boundaries}: YearsTimeLineTypes) => {
  const zoomedOutMode = useRecoilValue(zoomedOutModeAtom);
  const timeLineWidth = useCalculateTimeLineWidth(boundaries, zoomedOutMode);
  const actSetSpotlightYear = useActionSetSpotlightYear();

  return (
    <StyledTimeLine style={{width: `${timeLineWidth}px`}} x-component-name="StyledTimeLine">
      {boundaries.years.map((yInfo) => (
        <StyledYearMarker
          style={{width: `${calculateYearGridColumn(yInfo.daysInYear, zoomedOutMode)}px`}}
          key={`year-marker:${yInfo.year}`}
        >
          <StyledYearMarkerText onClick={() => actSetSpotlightYear(yInfo.year)}>
            {yInfo.year}
          </StyledYearMarkerText>
        </StyledYearMarker>
      ))}
    </StyledTimeLine>
  );
};

export default YearsTimeLine;
