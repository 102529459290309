import React, {useState, useRef} from 'react';
import Select, {SelectItemRenderer} from 'react-dropdown-select';
import {useRecoilValue} from 'recoil';

import {HIGHLIGHTING} from './colors';
import {getActiveItemSearchableSelector} from '../state/selectors';

import {StyledSearchField} from './_styled';
import LifeItemSearchable from '../domain/LifeItemSearchable';
import useActionSelectItem from '../state/actions/selectItem';
import {StyledColorDot, StyledStreamSelectItem} from './commons/_styled';
import {searchFieldTriggerOpenAtom} from '../state/atoms';
import useActionSearchFieldTriggerOpen from '../state/actions/searchFieldTriggerOpen';

const SearchField = () => {
  const wrapperRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const items = useRecoilValue(getActiveItemSearchableSelector);
  const triggerSearchFieldOpen = useRecoilValue(searchFieldTriggerOpenAtom);
  const actTrigger = useActionSearchFieldTriggerOpen();

  const actSelectItem = useActionSelectItem();
  const [selectValue, setSelectValue] = useState<LifeItemSearchable[]>([]);

  if (triggerSearchFieldOpen) {
    setTimeout(() => {
      // seems stupid, but the react-dropdown-select api gives us no other way
      const inputEl = wrapperRef.current?.querySelector(
        '.react-dropdown-select-content input'
      ) as HTMLElement;
      inputEl?.click();
      actTrigger(false);
    }, 12);
  }

  return (
    <StyledSearchField>
      <div ref={wrapperRef}>
        <Select
          dropdownPosition="top"
          color={HIGHLIGHTING}
          multi={false}
          searchable={true}
          clearable={true}
          clearOnBlur={true}
          clearOnSelect={true}
          options={items}
          values={selectValue}
          labelField="title"
          valueField="id"
          searchBy="searchable"
          placeholder="Search..."
          itemRenderer={CustomItemSelectItem}
          onDropdownOpen={onSearchDropdownOpen}
          onChange={onSearchChange}
        />
      </div>
    </StyledSearchField>
  );

  function onSearchDropdownOpen() {
    setSelectValue([]); // if the user clicks the search field, we want to clear the previously selected item
  }

  function onSearchChange(values: LifeItemSearchable[]) {
    if (values && values.length) {
      setSelectValue(values); // somehow this is needed, in order to make the "clear selected value on dropdown open"  work correctly.
      actSelectItem(values[0].id);
    }
  }
};

export default SearchField;

const CustomItemSelectItem = ({item, props, methods}: SelectItemRenderer<LifeItemSearchable>) => {
  const selected = methods.isSelected(item);
  const label = item.title;
  let className = 'react-dropdown-select-item';
  if (selected) className += ' react-dropdown-select-item-selected';

  return (
    <StyledStreamSelectItem
      onClick={() => methods.addItem(item)}
      role="option"
      aria-selected={selected ? 'true' : 'false'}
      aria-label={label}
      tabIndex={-1}
      className={className}
      $selected={selected}
    >
      {label}
      <StyledColorDot color={item.streamColor} />
    </StyledStreamSelectItem>
  );
};
