import preval from 'preval.macro';
import {format} from 'date-fns';

import packageJson from '../../../package.json';

import {StyledBuildInfo} from './_styled';

const buildTimestamp = preval`module.exports = new Date().getTime();`;
const BuildInfo = () => {
  const {version} = packageJson;

  return (
    <StyledBuildInfo>
      <b>My Life</b>{' '}
      <span>
        v{version} ({format(new Date(buildTimestamp), 'dd.MM.yy HH:mm')})
      </span>{' '}
      <a href="https://github.com/xeronimus/my-life" target="_blank" rel="noreferrer noopener">
        <i className="icon icon-github-circled-1" />
      </a>
    </StyledBuildInfo>
  );
};

export default BuildInfo;
