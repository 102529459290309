import React, {useEffect, useRef} from 'react';
import {useRecoilValue} from 'recoil';

import {selectedItemIdAtom, zoomedOutModeAtom} from '../../../state/atoms.ui';
import {formatDateNumeric} from '../../../data/timeUtil';
import {useCalculateStreamItemBarWidth, useCalculateStreamItemLeftOffset} from '../layouter';

import {
  StyledStreamItem,
  StyledStreamItemBar,
  StyledStreamItemData,
  StyledStreamItemDescription,
  StyledStreamItemTitle
} from './_styled';
import LifeItemWithStreamProps from '../../../domain/LifeItemWithStreamProps';
import {addDays} from 'date-fns';

interface StreamLaneItemTypes {
  item: LifeItemWithStreamProps;
  earliestDayInSpan: Date;
  totalDaysInSpan: number;
  onItemClick: () => void;
}

const StreamLaneItem = ({
  item,
  earliestDayInSpan,
  totalDaysInSpan,
  onItemClick
}: StreamLaneItemTypes) => {
  const selectedItemId = useRecoilValue(selectedItemIdAtom);
  const zoomedOutMode = useRecoilValue(zoomedOutModeAtom);

  const streamItemRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    if (item.id === selectedItemId && streamItemRef.current) {
      setTimeout(
        () =>
          //https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
          streamItemRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center', // Defines vertical alignment. One of start, center, end, or nearest. Defaults to start.
            inline: 'center' // Defines horizontal alignment. One of start, center, end, or nearest. Defaults to nearest.
          }),
        10
      );
    }
  }, [selectedItemId, item, zoomedOutMode]);

  const startFormatted = formatDateNumeric(item.startDateObj);
  const endFormatted = item.endDateObj ? formatDateNumeric(item.endDateObj) : '';

  const leftOffset = useCalculateStreamItemLeftOffset(item, earliestDayInSpan, zoomedOutMode);
  const streamItemBarWidth = useCalculateStreamItemBarWidth(
    item,
    addDays(earliestDayInSpan, totalDaysInSpan),
    zoomedOutMode
  );

  return (
    <StyledStreamItem
      title={item.title}
      $left={leftOffset}
      $highlighted={selectedItemId === item.id}
      $color={item.streamColor}
    >
      <StyledStreamItemBar
        style={{width: `${streamItemBarWidth}px`}}
        onClick={onItemDataClick}
        $color={item.streamColor}
      />
      <StyledStreamItemData ref={streamItemRef} onClick={onItemDataClick}>
        <StyledStreamItemTitle>
          <span>{item.title}</span>
          <span>
            {item.album && <i className="icon-picture-1" />}
            {item.photo && <i className="icon-picture" />}
            {item.place && <i className="icon-location" />}
          </span>
        </StyledStreamItemTitle>
        <StyledStreamItemDescription>{item.description}</StyledStreamItemDescription>
        <div>
          {startFormatted}
          {endFormatted && ` - ${endFormatted}`}
        </div>
      </StyledStreamItemData>
    </StyledStreamItem>
  );

  function onItemDataClick(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation(); // stop bubbling, so that "stream" does not register a click, otherwise will "deselect"
    onItemClick();
  }
};

export default StreamLaneItem;
