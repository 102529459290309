import StreamDescription from './StreamDescription';
import LifeItem from './LifeItem';
import uuid from '../data/uuid';

export default interface Stream extends StreamDescription {
  items: LifeItem[];
}

export const createNewStream = (): Stream => {
  return {
    id: uuid(),
    title: 'New Stream',
    color: '#ff0000',
    items: []
  };
};
