import styled from 'styled-components';

import {FONT_GREY, FONT_LIGHT_BORDER_GREY} from '../colors';
import {StyledInput} from '../commons/_styled';

export const StyledLife = styled.div`
  overflow: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const StyledStreamsWrapper = styled.div`
  height: calc(100% - 36px);
  overflow-x: hidden;
  overflow-y: scroll;
`;

export const StyledTimeLine = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background: white;
  height: 19px;
`;

interface StyledYearMarkerProps {
  $faded?: boolean;
}
export const StyledYearMarker = styled.div<StyledYearMarkerProps>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  border-right: 1px solid ${FONT_GREY};
  flex-shrink: 0;
  flex-grow: 0;
  color: ${(props) => (props.$faded ? 'gray' : 'inherit')};
`;

export const StyledYearMarkerText = styled.span`
  transition: font-size 0.1s linear;
  cursor: pointer;

  &:hover {
    font-size: 22px;
  }
`;

interface StyledYearsGridProps {
  $visible: boolean;
}

export const StyledYearsGrid = styled.div<StyledYearsGridProps>`
  position: absolute;
  top: 19px;
  bottom: 19px;
  left: 0;
  display: ${(props) => (props.$visible ? 'flex' : 'none')};
  flex-direction: row;
  flex-wrap: nowrap;
`;

export const StyledYearsGridColumn = styled.div`
  // box-sizing border-box is important. we set the width in pixels to the number of days in a year. therefore the 1px right border needs to fit into these 265/366 px
  // otherwise it would add up over time
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 1;
  flex-shrink: 0;
  flex-grow: 0;
`;

export const SpotlightYearMarker = styled.div`
  position: fixed;

  width: 100%;
  margin: 0 auto;
  top: calc(50% - 120px);
  color: rgba(0, 0, 0, 0.04);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > span {
    font-size: 262px;
    font-weight: 600;
  }

  > div {
    font-size: 80px;
    font-weight: 600;
  }
`;

export const StyledJumpToYearField = styled.div`
  z-index: 1000;
  position: fixed;
  margin-top: 8px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  > div {
    border: 1px solid ${FONT_LIGHT_BORDER_GREY};
    padding: 6px 8px;
    background: white;
    box-shadow:
      -6px 1px 9px 0 rgb(0 0 0 / 9%),
      6px 1px 9px 0 rgb(0 0 0 / 9%);
  }

  ${StyledInput} {
    width: 400px;
    border: none;
  }
`;
