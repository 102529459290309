import React, {useState} from 'react';
import {StyledInput} from '../commons/_styled';
import {StyledJumpToYearField} from './_styled';

interface JumpToYearFieldTypes {
  onYearSelected: (year: number) => void;
  onCancel: () => void;
}

const JumpToYearField = ({onYearSelected, onCancel}: JumpToYearFieldTypes) => {
  const [txt, setTxt] = useState<string>('');

  return (
    <StyledJumpToYearField>
      <div>
        <StyledInput
          placeholder="Jump to a year..."
          value={txt}
          onChange={onChange}
          onKeyUp={onKeyUp}
          autoFocus={true}
          onBlur={() => onCancel()}
        />
      </div>
    </StyledJumpToYearField>
  );

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    const vWithoutNonNumbers = (e.target.value || '').replace(/\D/g, '');
    setTxt(vWithoutNonNumbers);
  }

  function onKeyUp(e: React.KeyboardEvent) {
    if (e.code === 'Enter') {
      const year = parseInt(txt, 10);
      if (!Number.isNaN(year) && year > 0) {
        onYearSelected(year);
      }
    } else if (e.code === 'Escape') {
      onCancel();
    }
  }
};

export default JumpToYearField;
