import {useRecoilValue, useSetRecoilState} from 'recoil';
import {selectedItemIdAtom, spotlightYearAtom} from '../atoms.ui';
import {getStreamsOrThrow} from '../selectors';
import {getItemsInYearFlat} from '../../domain/Life';

const useActionSetSpotlightYear = (): ((year: number) => void) => {
  const setSpotlightYear = useSetRecoilState(spotlightYearAtom);
  const setSelectedItemId = useSetRecoilState(selectedItemIdAtom);
  const streams = useRecoilValue(getStreamsOrThrow);

  return (year: number): void => {
    setSpotlightYear(year);

    if (year > 0) {
      // select the first item in the spotlight year (if there are any items)
      const items = getItemsInYearFlat(streams, year);
      if (items.length > 0) {
        setSelectedItemId(items[0].id);
      } else {
        setSelectedItemId(undefined);
      }
    }
  };
};

export default useActionSetSpotlightYear;
