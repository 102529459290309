export default interface PartialPhotoList {
  photos: {
    id: string;
    baseUrl: string; //  Note: google photo baseUrls expire after 60 minutes
  }[];
  nextPageToken?: string;
  allLoaded?: boolean;
}

export const emptyPhotoList = (): PartialPhotoList => ({
  photos: [],
  nextPageToken: undefined,
  allLoaded: false
});
