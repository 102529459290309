import {useEffect, useState} from 'react';

import PartialPhotoList, {emptyPhotoList} from '../../data/service/PartialPhotoList';
import GooglePhotosService from '../../data/service/impl/google/GooglePhotosService';

const MAX_PHOTOS_TO_LOAD_PER_ALBUM = 100;

/**
 * Loads all photos of a given album (max MAX_PHOTOS_TO_LOAD_PER_ALBUM photos). and picks N random photos from the list.
 *
 * @returns a tuple where the first entry is an array of photo urls, the second entry is a boolean that indicates whether all photos are loaded.
 */
const useLoadPhotosFromAlbum = (
  accessToken: string,
  albumId: string,
  howManyPhotosToPick: number
): [string[], boolean] => {
  const [partialPhotoList, setPartialPhotoList] = useState<PartialPhotoList>(emptyPhotoList());
  const [pickedPhotoUrls, setPickedPhotosUrls] = useState<string[]>([]);

  useEffect(() => {
    setPartialPhotoList(emptyPhotoList()); // we need to reset the partial list
    setPickedPhotosUrls([]); // we need to reset picked photos whenever albumId changes
    doLoad(undefined, emptyPhotoList()); // start loading from the album
  }, [albumId, howManyPhotosToPick]);

  return [pickedPhotoUrls, partialPhotoList.allLoaded || false];

  function doLoad(nextPageToken: string | undefined, preList: PartialPhotoList) {
    GooglePhotosService.loadPhotosFromAlbum(accessToken, albumId, nextPageToken).then((pl) => {
      const modifiedPl: PartialPhotoList = {
        photos: [...preList.photos, ...pl.photos],
        nextPageToken: pl.nextPageToken
      };

      if (pl.nextPageToken && modifiedPl.photos.length < MAX_PHOTOS_TO_LOAD_PER_ALBUM) {
        setPartialPhotoList(modifiedPl);
        doLoad(modifiedPl.nextPageToken, modifiedPl);
      } else {
        modifiedPl.allLoaded = true;
        setPartialPhotoList(modifiedPl);
        setPickedPhotosUrls(
          pickNrandom(modifiedPl.photos, howManyPhotosToPick).map((p) => p.baseUrl)
        );
      }
    });
  }
};

export default useLoadPhotosFromAlbum;

/**
 * picks N random elements from the given array
 */
function pickNrandom<T>(arr: T[], toPickCount: number): T[] {
  let len = arr.length;
  if (len < 1) {
    return [];
  }
  let n = Math.min(toPickCount, len - 1);
  const result = new Array(n);
  const taken = new Array(len);

  while (n--) {
    const x = Math.floor(Math.random() * len);
    result[n] = arr[x in taken ? taken[x] : x];
    taken[x] = --len in taken ? taken[len] : len;
  }
  return result;
}
