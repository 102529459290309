import {getYear, set, addYears, differenceInDays} from 'date-fns';

import Boundaries from '../domain/Boundaries';
import LifeItem from '../domain/LifeItem';
import {getYearInfoForSpan} from '../domain/YearInfo';
import {isAfterDate, isBeforeDate, resetTime} from './timeUtil';

/**
 * Given a list of LifeItems, derive the "boundaries" (start and end of the "visible timeframe")
 */
export default function deriveBoundaries(items: LifeItem[]): Boundaries {
  if (items.length < 1) {
    throw new Error('cannot have an empty list of items');
  }

  const {earliestStartDate, latestEndDate} = findFirstAndLastDay(items);

  const endDatePlusOne = addYears(latestEndDate, 1);
  const totalDaysInSpan = differenceInDays(
    resetToLastDayOfYear(endDatePlusOne),
    resetToFirstDayOfYear(earliestStartDate)
  );
  const firstYear = getYear(earliestStartDate);
  const lastYear = getYear(endDatePlusOne);
  const yearSpan = lastYear - firstYear + 1;

  const firstDay = resetToFirstDayOfYear(set(new Date(), {year: firstYear}));

  return {
    earliestStartDate,
    latestEndDate,
    firstYear,
    lastYear,
    firstDay,
    yearSpan,
    totalDaysInSpan,
    years: getYearInfoForSpan(yearSpan, firstYear)
  };
}

const findFirstAndLastDay = (items: LifeItem[]) => {
  let earliestStartDate = new Date(2200, 10, 10);
  let latestEndDate = new Date(1900, 10, 10);

  items.forEach((item) => {
    if (isBeforeDate(item.startDateObj, earliestStartDate)) {
      earliestStartDate = item.startDateObj;
    }

    if (item.endDateObj && isAfterDate(item.endDateObj, latestEndDate)) {
      latestEndDate = item.endDateObj;
    } else if (isAfterDate(item.startDateObj, latestEndDate)) {
      latestEndDate = item.startDateObj;
    }
  });

  earliestStartDate = resetTime(earliestStartDate);
  latestEndDate = resetTime(latestEndDate);

  return {earliestStartDate, latestEndDate};
};

const resetToFirstDayOfYear = (dateObject: Date): Date =>
  set(dateObject, {
    date: 0,
    month: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0
  });

const resetToLastDayOfYear = (dateObject: Date): Date =>
  set(dateObject, {
    date: 31,
    month: 11,
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0
  });
