import React, {useState, useEffect, useRef} from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';
import {addDays, isSameDay} from 'date-fns';
import DatePicker from 'react-datepicker';
import Select, {SelectItemRenderer, SelectRenderer} from 'react-dropdown-select';
import {sanitizeUrl} from '@braintree/sanitize-url';
import normalizeUrl from 'normalize-url';

import {birthAtom} from '../../state/atoms';
import {getStreamsInfoSelector} from '../../state/selectors';
import {hasUnsavedItemChangesAtom} from '../../state/atoms.ui';
import {HIGHLIGHTING} from '../colors';
import AlbumList from './AlbumList';
import PhotoDisplay from '../commons/PhotoDisplay';
import Button from '../commons/Button';
import {formatRange, getAgeString, isBeforeDate, resetTime} from '../../data/timeUtil';
import LifeItem from '../../domain/LifeItem';
import StreamDescription from '../../domain/StreamDescription';
import Place from '../../domain/Place';
import {Checkbox} from '../commons/Checkbox';
import MapWithLocationSelect from './MapWithLocationSelect';

import {
  StyledDateInputWrapper,
  StyledDateRange,
  StyledEditorButtonGroup,
  StyledItemDetail,
  StyledOngoingAndAgeWrapper,
  StyledStreamSelectContent,
  StyledUrlsEditList
} from './_styled';
import {StyledTextarea} from '../_styled';
import {
  StyledColorDot,
  StyledInput,
  StyledPhotoItem,
  StyledPullThemRight,
  StyledStreamSelectItem
} from '../commons/_styled';
import IconButton from '../commons/IconButton';
import FavIcon from '../commons/FavIcon';
import SinglePhotoPicker from './SinglePhotoPicker';
import GoogleSinglePhoto from '../commons/GoogleSinglePhoto';

enum PickerTypeVisible {
  NONE,
  ALBUM,
  SINGLEPHOTO,
  PLACE
}

interface ItemDetailEditFormTypes {
  item: LifeItem;
  onSave: (item: LifeItem) => void;
  onDelete: (itemId: string) => void;
}

const ItemDetailEditForm = ({item, onSave, onDelete}: ItemDetailEditFormTypes) => {
  const streams = useRecoilValue(getStreamsInfoSelector);
  const birth = useRecoilValue(birthAtom);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useRecoilState(hasUnsavedItemChangesAtom);

  const [pickerType, setPickerType] = useState<PickerTypeVisible>(PickerTypeVisible.NONE);
  const [datesValid, setDatesValid] = useState(true);

  const titleInputRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  /* item & item fields */
  const [title, setTitle] = useState<string>(item.title);
  const [description, setDescription] = useState<string>(item.description);
  const [startDate, setStartDate] = useState<Date>(item.startDateObj);
  const [ongoing, setOngoing] = useState<boolean>(item.ongoing || false);
  const [endDate, setEndDate] = useState<Date | undefined>(item.endDateObj);
  const [selectedStream, setSelectedStream] = useState<StreamDescription>(
    deriveStreamSelection(item)
  );
  const [album, setAlbum] = useState<string | undefined>(item.album);
  const [singlePhoto, setSinglePhoto] = useState<string | undefined>(item.photo);
  const [place, setPlace] = useState<Place | undefined>(item.place);
  const [urls, setUrls] = useState<string[]>(item.urls);

  const googleMapPlaceUrl = place
    ? `https://www.google.com/maps/place/${place.location.lat},${place.location.lng}`
    : undefined;

  useEffect(() => {
    resetLocalState(); // update all fields, if item changes
  }, [item, streams]);

  useEffect(() => {
    setDatesValid(hasValidDates());
  }, [startDate, endDate]);

  if (!item) {
    return null;
  }

  return (
    <StyledItemDetail color={selectedStream.color}>
      <StyledInput
        value={title}
        onChange={(e) => {
          setTitle(e.target.value);
          setHasUnsavedChanges(true);
        }}
        autoFocus={true}
        ref={titleInputRef}
        placeholder="Title"
        $warning={!title}
      />

      <StyledDateInputWrapper>
        <DatePicker
          calendarStartDay={1}
          dateFormat="dd.MM.yyyy"
          onChange={(newStartDate) => {
            if (newStartDate) {
              setStartDate(newStartDate);
              setHasUnsavedChanges(true);
            }
          }}
          placeholderText="Start Date"
          selectsStart={true}
          selected={startDate}
          startDate={startDate}
          endDate={endDate}
          className={datesValid ? '' : 'warning'}
        />

        <DatePicker
          calendarStartDay={1}
          dateFormat="dd.MM.yyyy"
          onChange={onEndDateChanged}
          placeholderText="End Date"
          selectsEnd={true}
          selected={endDate}
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          className={datesValid ? '' : 'warning'}
        />

        <Checkbox label="Ongoing" value={ongoing} onChange={onOngoingToggle} />
      </StyledDateInputWrapper>

      <StyledOngoingAndAgeWrapper>
        {endDate && (
          <StyledDateRange>{formatRange(startDate, addDays(endDate, 1))}</StyledDateRange>
        )}
        {ongoing && (
          <StyledDateRange>For {formatRange(startDate, addDays(new Date(), 1))}</StyledDateRange>
        )}
        {!endDate && <StyledDateRange></StyledDateRange>}
        <StyledDateRange>
          <span>{getAgeString(birth, startDate)}</span>
        </StyledDateRange>
      </StyledOngoingAndAgeWrapper>

      <StyledTextarea
        value={description}
        onChange={(e) => {
          setDescription(e.target.value);
          setHasUnsavedChanges(true);
        }}
      >
        Description
      </StyledTextarea>

      <Select
        color={HIGHLIGHTING}
        multi={false}
        options={streams}
        labelField="title"
        valueField="id"
        values={[selectedStream]}
        onChange={onStreamSelectChange}
        itemRenderer={CustomStreamSelectItem}
        contentRenderer={CustomStreamSelectContent}
      />

      <h4>External Resources</h4>
      <StyledUrlsEditList>
        {urls.map((u, i) => (
          <li key={`item:${item.id}:urls_${i}`}>
            <a href={sanitizeUrl(u)} rel="noreferrer noopener" target="_blank">
              <FavIcon url={sanitizeUrl(u)} />
            </a>
            <StyledInput
              value={u}
              onChange={(e) => {
                onUrlEdited(e.target.value, i);
                setHasUnsavedChanges(true);
              }}
              onBlur={() => onUrlBlur(i)}
              placeholder="https://"
            />
            <IconButton
              className="icon-trash"
              onClick={() => {
                onUrlRemoved(i);
                setHasUnsavedChanges(true);
              }}
            />
          </li>
        ))}
      </StyledUrlsEditList>

      <IconButton
        className="icon-plus"
        onClick={() => {
          setUrls([...urls, '']);
          setHasUnsavedChanges(true);
        }}
      />

      <h4>{singlePhoto ? 'Photo' : 'Photos'}</h4>
      {album &&
        pickerType !== PickerTypeVisible.ALBUM &&
        pickerType !== PickerTypeVisible.SINGLEPHOTO && (
          <React.Fragment>
            <PhotoDisplay albumId={album} />
            <StyledPullThemRight>
              <Button onClick={() => setPickerType(PickerTypeVisible.ALBUM)}>
                <i className="icon-picture-1" /> Change Album
              </Button>
            </StyledPullThemRight>
          </React.Fragment>
        )}

      {singlePhoto &&
        pickerType !== PickerTypeVisible.ALBUM &&
        pickerType !== PickerTypeVisible.SINGLEPHOTO && (
          <React.Fragment>
            <StyledPhotoItem>
              <GoogleSinglePhoto photoId={singlePhoto} urlPostfix={'=h320-w320-c'} />
            </StyledPhotoItem>
            <StyledPullThemRight>
              <Button onClick={() => setPickerType(PickerTypeVisible.SINGLEPHOTO)}>
                <i className="icon-picture" /> Change Photo
              </Button>
            </StyledPullThemRight>
          </React.Fragment>
        )}

      {!album &&
        !singlePhoto &&
        pickerType !== PickerTypeVisible.ALBUM &&
        pickerType !== PickerTypeVisible.SINGLEPHOTO && (
          <StyledEditorButtonGroup>
            <Button onClick={() => setPickerType(PickerTypeVisible.SINGLEPHOTO)}>
              <i className="icon-picture" /> Select Single Photo
            </Button>

            <Button onClick={() => setPickerType(PickerTypeVisible.ALBUM)}>
              <i className="icon-picture-1" /> Select Photo-Album
            </Button>
          </StyledEditorButtonGroup>
        )}

      {pickerType === PickerTypeVisible.ALBUM && (
        <AlbumList onAlbumSelected={onAlbumSelected} onAlbumCleared={onAlbumCleared} />
      )}

      {pickerType === PickerTypeVisible.SINGLEPHOTO && (
        <SinglePhotoPicker
          preselectDate={item.startDateObj}
          onSinglePhotoSelected={onSinglePhotoSelected}
          onSinglePhotoCleared={onSinglePhotoCleared}
        />
      )}

      <h4>Place</h4>

      {place && pickerType !== PickerTypeVisible.PLACE && (
        <React.Fragment>
          <div>
            <a href={googleMapPlaceUrl} target="_blank" rel="noopener noreferrer">
              <FavIcon url="https://maps.google.com" /> <b>{place.name}</b>
            </a>
          </div>
          <StyledPullThemRight>
            <Button onClick={() => setPickerType(PickerTypeVisible.PLACE)}>
              <i className="icon-location" /> Change Place
            </Button>
          </StyledPullThemRight>
        </React.Fragment>
      )}

      {!place && pickerType !== PickerTypeVisible.PLACE && (
        <StyledPullThemRight>
          <Button onClick={() => setPickerType(PickerTypeVisible.PLACE)}>
            <i className="icon-location" /> Select Place
          </Button>
        </StyledPullThemRight>
      )}

      {pickerType === PickerTypeVisible.PLACE && (
        <MapWithLocationSelect
          place={place}
          onPlaceSelected={onPlaceSelected}
          onPlaceCleared={onPlaceCleared}
        />
      )}

      <StyledEditorButtonGroup>
        <Button onClick={onDeleteButtonClick}>
          <i className="icon-trash" /> Delete Item
        </Button>

        {hasUnsavedChanges && (
          <Button onClick={resetLocalState}>
            <i className="icon-cancel" /> Discard Changes
          </Button>
        )}
        {hasUnsavedChanges && (
          <Button onClick={onSaveButtonClick} primary disabled={!datesValid || !title}>
            <i className="icon-floppy" /> Save Item
          </Button>
        )}
      </StyledEditorButtonGroup>
    </StyledItemDetail>
  );

  function onEndDateChanged(newEndDate: Date | null) {
    if (newEndDate) {
      setOngoing(false);
    }
    setEndDate(newEndDate || undefined);
    setHasUnsavedChanges(true);
  }

  function onOngoingToggle(checked: boolean) {
    if (checked) {
      setEndDate(undefined);
    }
    setOngoing(checked);
    setHasUnsavedChanges(true);
  }

  function onPlaceSelected(place: Place) {
    console.log('place selected', place);
    setPlace(place);
    setPickerType(PickerTypeVisible.NONE);
    setHasUnsavedChanges(true);
  }

  function onPlaceCleared() {
    console.log('place cleared');
    setPlace(undefined);
    setPickerType(PickerTypeVisible.NONE);
    setHasUnsavedChanges(true);
  }

  function onAlbumSelected(albumId: string) {
    setSinglePhoto(undefined); // if user selects an album, un-set single photo
    setAlbum(albumId);
    setPickerType(PickerTypeVisible.NONE);
    setHasUnsavedChanges(true);
  }

  function onAlbumCleared() {
    setAlbum(undefined);
    setPickerType(PickerTypeVisible.NONE);
    setHasUnsavedChanges(true);
  }

  function onSinglePhotoSelected(photoId: string) {
    setAlbum(undefined); // if user selects a single photo, un-set album
    setSinglePhoto(photoId);
    setPickerType(PickerTypeVisible.NONE);
    setHasUnsavedChanges(true);
  }

  function onSinglePhotoCleared() {
    setSinglePhoto(undefined);
    setPickerType(PickerTypeVisible.NONE);
    setHasUnsavedChanges(true);
  }

  function onUrlEdited(modifiedUrl: string, urlIndex: number) {
    const modifiedArray = urls.map((item, index) => {
      if (index !== urlIndex) {
        return item;
      }

      return modifiedUrl;
    });

    setUrls(modifiedArray);
  }

  function onUrlBlur(urlIndex: number) {
    const url = urls[urlIndex];
    if (url) {
      const modifiedNormalizedSanitized = sanitizeUrl(
        // sanitizes
        normalizeUrl(url.toLowerCase(), {forceHttps: true}) // makes sure the url has correct protocol, relative paths are resolved, etc.
      );
      onUrlEdited(modifiedNormalizedSanitized, urlIndex);
    }
  }

  function onUrlRemoved(urlIndex: number) {
    let newArray = urls.slice();
    newArray.splice(urlIndex, 1);
    setUrls(newArray);
  }

  function deriveStreamSelection(itm: LifeItem) {
    const matchingStream = streams.find((s) => s.id === itm.stream);
    if (!matchingStream) {
      throw new Error(
        `Inconsistency! item with id ${itm.id} references nonexisting stream ${itm.stream}`
      );
    }
    return matchingStream;
  }

  function onStreamSelectChange(values: StreamDescription[]) {
    if (values && values.length > 0) {
      const [newSelectedStream] = values;
      if (selectedStream.id === newSelectedStream.id) {
        return; // re-selected same stream
      }
      setSelectedStream(newSelectedStream);
      setHasUnsavedChanges(true);
    }
  }

  function resetLocalState() {
    setTitle(item.title);
    setDescription(item.description);
    setStartDate(item.startDateObj);
    setEndDate(item.endDateObj);
    setOngoing(item.ongoing || false);
    setSelectedStream(deriveStreamSelection(item));
    setAlbum(item.album);
    setSinglePhoto(item.photo);
    setPlace(item.place);
    setUrls(item.urls);
    setDatesValid(true);

    setPickerType(PickerTypeVisible.NONE);
    setHasUnsavedChanges(false);

    if (titleInputRef && titleInputRef.current) {
      titleInputRef.current.focus();
    }
  }

  function onDeleteButtonClick() {
    onDelete(item.id);
  }

  function hasValidDates() {
    if (!startDate) {
      return false;
    }

    if (endDate) {
      const startDateObj = resetTime(startDate);
      const endDateObj = resetTime(endDate);
      if (!isBeforeDate(startDateObj, endDateObj)) {
        return false;
      }
    }

    return true;
  }

  function onSaveButtonClick() {
    if (!title || !hasValidDates()) {
      return;
    }

    const itemToSave: LifeItem = {
      ...item,
      title,
      description,
      album,
      photo: singlePhoto,
      place,
      ongoing,
      urls: urls.map((u) => sanitizeUrl(u)),
      stream: selectedStream.id
    };

    const [startDateObj, endDateObj] = getMeMyDates();
    itemToSave.startDateObj = startDateObj;
    itemToSave.endDateObj = endDateObj;

    onSave(itemToSave);
  }

  function getMeMyDates(): [Date, Date | undefined] {
    const startDateObj = resetTime(startDate);

    if (!endDate) {
      return [startDateObj, undefined];
    }

    let endDateObj = resetTime(endDate);

    // datepicker might select "range" with same start and end date
    if (isSameDay(startDateObj, endDateObj)) {
      return [startDateObj, undefined];
    } else {
      return [startDateObj, endDateObj];
    }
  }
};

export default ItemDetailEditForm;

const CustomStreamSelectItem = ({item, props, methods}: SelectItemRenderer<StreamDescription>) => {
  const selected = methods.isSelected(item);
  const label = item.title;
  let className = 'react-dropdown-select-item';
  if (selected) className += ' react-dropdown-select-item-selected';

  return (
    <StyledStreamSelectItem
      onClick={() => methods.addItem(item)}
      role="option"
      aria-selected={selected ? 'true' : 'false'}
      aria-label={label}
      tabIndex={-1}
      className={className}
      $selected={selected}
    >
      {label}
      <StyledColorDot color={item.color} />
    </StyledStreamSelectItem>
  );
};

const CustomStreamSelectContent = ({state}: SelectRenderer<StreamDescription>) => {
  if (state.values?.length) {
    return (
      <StyledStreamSelectContent>
        {state.values[0].title}
        <StyledColorDot color={state.values[0].color} />
      </StyledStreamSelectContent>
    );
  } else {
    return <div></div>;
  }
};
