import {useRecoilValue, useSetRecoilState} from 'recoil';

import {streamsAtom} from '../atoms';
import LifeItem from '../../domain/LifeItem';
import {updateStreamsWithModifiedItem} from '../../data/streamsUpdateFunctions';
import {getStreamsOrThrow} from '../selectors';

/**
 *
 */
const useActionModifyItem = (): ((itemToSave: LifeItem) => void) => {
  const streams = useRecoilValue(getStreamsOrThrow);
  const setStreams = useSetRecoilState(streamsAtom);

  return (itemToSave: LifeItem): void => {
    const modifiedStreams = updateStreamsWithModifiedItem(streams, itemToSave);
    setStreams(modifiedStreams);
  };
};

export default useActionModifyItem;
