import React, {useMemo} from 'react';

import {itemsToRows} from '../../../data/itemsToRows';
import SpotlightStreamItem from './SpotlightStreamItem';

import {StyledStream, StyledStreamLabel, StyledSpotlightStreamRow} from './_styled';
import LifeItemWithStreamProps from '../../../domain/LifeItemWithStreamProps';

interface SpotlightStreamTypes {
  itemsFlat: LifeItemWithStreamProps[];
  year: number;
  onDeselectItem: () => void;
  onSelectItem: (itemId: string) => void;
}

const SpotlightStream = ({itemsFlat, year, onDeselectItem, onSelectItem}: SpotlightStreamTypes) => {
  const rows = useMemo(() => itemsToRows(itemsFlat, 48), [itemsFlat]);

  const stream = {
    color: '#7a7a7a',
    title: 'Spotlight on ' + year
  };

  return (
    <StyledStream $color={stream.color} onClick={() => onDeselectItem()}>
      <StyledStreamLabel>
        <div>{stream.title}</div>
        <div>{stream.title}</div>
      </StyledStreamLabel>
      {rows.map((items, rowIndex) => (
        <StyledSpotlightStreamRow $index={rowIndex} key={`row:spotlight:${year}:${rowIndex}`}>
          {items.map((item, itemIndex) => (
            <SpotlightStreamItem
              key={`item:spotlight:${year}:${itemIndex}:${rowIndex}`}
              item={item}
              onItemClick={() => onSelectItem(item.id)}
            />
          ))}
        </StyledSpotlightStreamRow>
      ))}
    </StyledStream>
  );
};

export default SpotlightStream;
