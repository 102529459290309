import React from 'react';

import {StyledCheckboxMarker, StyledCheckboxWrapper} from './_styled';

interface CheckboxTypes {
  value: boolean;
  label: string;
  onChange: (v: boolean) => void;
}

export const Checkbox = ({value, label, onChange}: CheckboxTypes) => (
  <StyledCheckboxWrapper onClick={() => onChange(!value)}>
    {label}
    <StyledCheckboxMarker $checked={value} />
  </StyledCheckboxWrapper>
);
