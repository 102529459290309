import {useCallback, useEffect} from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';

import {birthAtom, streamsAtom} from './atoms';
import {userContextAtom} from './atoms.user';
import {saveLife} from '../data/service/lifeService';
import {currentlySavingAtom, SIDEBAR_CONTENT, sideBarContentAtom} from './atoms.ui';
import usePrevious from '../components/commons/usePrevious';
import Stream from '../domain/Stream';

/**
 * This (null-) component subscribes to the "streamsAtom" (recoil) and persists the streams (i.e. the Life) to storage when the streams change.
 *
 */
export default function LifePersistorObserver() {
  const setCurrentlySaving = useSetRecoilState(currentlySavingAtom);
  const setSideBarContent = useSetRecoilState(sideBarContentAtom);
  const streams = useRecoilValue(streamsAtom);
  const birth = useRecoilValue(birthAtom);
  const prevStreams = usePrevious<Stream[] | undefined>(streams);
  const userContext = useRecoilValue(userContextAtom);

  const persistStreams = useCallback(() => {
    const hasStreamsAndPreviousStreamsWasDefined = !!(streams && prevStreams);
    const hasChanged = prevStreams !== streams;
    if (userContext?.accessToken && hasStreamsAndPreviousStreamsWasDefined && hasChanged) {
      setCurrentlySaving(true);
      setTimeout(async () => {
        try {
          await saveLife(userContext.accessToken, streams, birth);
        } catch (e) {
          console.error(e);
          setSideBarContent(SIDEBAR_CONTENT.ERROR);
        }
        setCurrentlySaving(false);
      }, 1);
    }
  }, [streams, prevStreams, userContext, birth, setCurrentlySaving, setSideBarContent]);

  useEffect(() => void persistStreams(), [persistStreams]);

  return null;
}
