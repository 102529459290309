import {formatYearOnly} from '../data/timeUtil';
import LifeItemWithStreamProps from './LifeItemWithStreamProps';

/**
 * A reduced LifeItem Entity, that contains a "searchable" property,
 * which contains all fields concatenated that the user wants to search for (title, description)
 */
export default interface LifeItemSearchable {
  id: string;
  title: string;
  searchable: string;
  streamColor: string;
}

export const itemsToSearchableItems = (itemsFlat: LifeItemWithStreamProps[]) =>
  itemsFlat
    .map(
      (item): LifeItemSearchable => ({
        id: item.id,
        streamColor: item.streamColor,
        title: item.title
          ? `${item.title} (${formatYearOnly(item.startDateObj)})`
          : `-no-title- (${formatYearOnly(item.startDateObj)})`,
        searchable: `${item.title} ${item.description}`
      })
    )
    .sort((iA, iB) => iA.title.localeCompare(iB.title));
