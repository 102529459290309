import React, {useEffect, useRef} from 'react';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {ThemeProvider} from 'styled-components';

import StreamLane from './stream/StreamLane';
import {YearGrid} from './YearGrid';
import JumpToYearField from './JumpToYearField';
import YearsTimeLine from './YearsTimeLine';
import useActionAddNewItem from '../../state/actions/addNewItem';
import useActionSelectItem from '../../state/actions/selectItem';
import useActionLoadAllAlbums from '../../state/actions/loadAllAlbums';
import {
  gridVisibleAtom,
  jumpToYearAtom,
  jumpToYearInputVisibleAtom,
  lifeHorizontalPosAtom,
  zoomedOutModeAtom
} from '../../state/atoms.ui';
import {getActiveStreamsSelector, getBoundariesSelector} from '../../state/selectors';
import rightMouseDragRefCb, {RightMouseDragRef} from './rightMouseDragRefCb';
import {
  calculateCurrentHorizontalPosition,
  calculateHorizontalPositionOfYear,
  useCalculateStreamsWrapperWidth
} from './layouter';

import {StyledLife, StyledStreamsWrapper} from './_styled';

/**
 *
 */
const Life = () => {
  const boundaries = useRecoilValue(getBoundariesSelector);
  const gridVisible = useRecoilValue(gridVisibleAtom);
  const zoomedOutMode = useRecoilValue(zoomedOutModeAtom);
  const activeStreams = useRecoilValue(getActiveStreamsSelector);
  const [jumpToYear, setJumpToYear] = useRecoilState(jumpToYearAtom);
  const [jumpToYrVisible, setJumpToYrVisible] = useRecoilState(jumpToYearInputVisibleAtom);
  const setHorizontalPos = useSetRecoilState(lifeHorizontalPosAtom);

  const actAddNewItem = useActionAddNewItem();
  const actSelectItem = useActionSelectItem();
  const actLoadAllAlbums = useActionLoadAllAlbums();

  const streamsWrapperWidth = useCalculateStreamsWrapperWidth(boundaries, zoomedOutMode);
  const rightMouseHorizDragRef = useRef() as React.MutableRefObject<RightMouseDragRef>;

  let lifeElement: HTMLDivElement | null;

  useEffect(() => {
    updateLifeHorizontalPos(lifeElement);
  }, [zoomedOutMode]);

  useEffect(() => {
    actLoadAllAlbums();
  }, []);

  useEffect(() => {
    if (lifeElement) {
      const position = calculateHorizontalPositionOfYear(boundaries, zoomedOutMode, jumpToYear);
      if (position) {
        lifeElement.scrollTo({
          left: position,
          behavior: 'smooth'
        });
      }
    }
  }, [jumpToYear]);

  return (
    <ThemeProvider theme={{zoomedOut: zoomedOutMode}}>
      <StyledLife ref={lifeRefCb} x-component-name="StyledLife">
        {jumpToYrVisible && (
          <JumpToYearField onYearSelected={onJumpToYearSelected} onCancel={onJumpToYearCanceled} />
        )}
        <YearGrid boundaries={boundaries} visible={gridVisible} zoomedOutMode={zoomedOutMode} />
        <YearsTimeLine boundaries={boundaries} />

        <StyledStreamsWrapper
          style={{width: `${streamsWrapperWidth}px`}}
          x-component-name="StyledStreamsWrapper"
        >
          {activeStreams.map((stream) => (
            <StreamLane
              key={`stream:${stream.id}`}
              stream={stream}
              boundaries={boundaries}
              onAddNewItem={(itemStartDaysDelta) => onAddNewItem(itemStartDaysDelta, stream.id)}
              onDeselectItem={() => actSelectItem(undefined)}
              onSelectItem={actSelectItem}
            />
          ))}
        </StyledStreamsWrapper>

        <YearsTimeLine boundaries={boundaries} />
      </StyledLife>
    </ThemeProvider>
  );

  function onJumpToYearSelected(year: number) {
    setJumpToYrVisible(false);
    setJumpToYear(year);
  }

  function onJumpToYearCanceled() {
    setJumpToYrVisible(false);
  }

  function lifeRefCb(element: HTMLDivElement | null) {
    lifeElement = element;
    rightMouseDragRefCb(element, rightMouseHorizDragRef, updateLifeHorizontalPos);
  }

  function updateLifeHorizontalPos(element: HTMLDivElement | null) {
    if (!element) {
      return;
    }
    setHorizontalPos(calculateCurrentHorizontalPosition(element, zoomedOutMode));
  }

  function onAddNewItem(itemStartDaysDelta: number, streamId: string) {
    actAddNewItem(itemStartDaysDelta, streamId);
  }
};

export default Life;
