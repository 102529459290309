import React, {useEffect, useRef, useState} from 'react';

import IconButton from '../commons/IconButton';
import Place from '../../domain/Place';
import Map from '../../data/service/Map';
import LatLng from '../../domain/LatLng';
import Button from '../commons/Button';
import mapService from '../../data/service/impl/google/GoogleMapService';

import {StyledInput} from '../commons/_styled';
import {
  StyledBelowMapInfos,
  StyledEditorButtonGroup,
  StyledMapLocationSearchControls,
  StyledMapWrapper
} from './_styled';

const CENTER_SWITZERLAND = {
  lat: 46.7985124,
  lng: 8.2317827
};

interface MapWithLocationSelectTypes {
  place?: Place;
  onPlaceSelected: (place: Place) => void;
  onPlaceCleared: () => void;
}

const MapWithLocationSelect = ({
  place,
  onPlaceSelected,
  onPlaceCleared
}: MapWithLocationSelectTypes) => {
  const [query, setQuery] = useState<string>('');
  const [innerSelectedPlace, setInnerSelectedPlace] = useState<Place | undefined>(place);
  const mapInstance = useRef() as React.MutableRefObject<Map>;
  const mapContainerRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    initializeMap();
  }, []);

  useEffect(() => {
    setInnerSelectedPlace(place);
  }, [place]);

  return (
    <StyledMapWrapper>
      <StyledMapLocationSearchControls>
        <div>
          <StyledInput
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            onKeyUp={onLocationQueryKeyUp}
            placeholder="Search place..."
          />
        </div>
        <IconButton onClick={onLocationFindClick} className={'icon-search'} />
      </StyledMapLocationSearchControls>

      <div ref={mapContainerRef} style={{height: '320px'}}>
        the map container
      </div>

      <StyledBelowMapInfos>
        {!innerSelectedPlace && <div>Drag the marker on the map to select a place</div>}
        {innerSelectedPlace && (
          <div>
            <b>{innerSelectedPlace.name}</b>
          </div>
        )}

        <StyledEditorButtonGroup>
          {place && (
            <Button onClick={() => onPlaceCleared()}>
              <i className="icon-cancel" /> Clear place
            </Button>
          )}

          {innerSelectedPlace && (
            <Button onClick={() => onPlaceSelected(innerSelectedPlace)}>
              <i className="icon-ok" />
              Apply
            </Button>
          )}
        </StyledEditorButtonGroup>
      </StyledBelowMapInfos>
    </StyledMapWrapper>
  );

  function initializeMap() {
    if (!mapContainerRef.current) {
      return;
    }

    const center = place?.location || CENTER_SWITZERLAND;
    mapService
      .initializeMapComponent(mapContainerRef.current, center, onMarkerDragged)
      .then((mapI) => {
        mapInstance.current = mapI;
        if (place?.location) {
          mapI.zoomTo(place?.location);
        }
      });
  }

  function onLocationQueryKeyUp(e: React.KeyboardEvent) {
    if (e.code === 'Enter') {
      onLocationFindClick();
    }
  }

  function onMarkerDragged(location: LatLng) {
    mapService.reverseGeocode(location).then((place) => {
      if (place) {
        setInnerSelectedPlace({
          location,
          name: place
        });
      }
    });
  }

  function onLocationFindClick() {
    if (query && query.length > 2) {
      mapService.geocode(query).then((location) => {
        if (location) {
          mapInstance.current.zoomTo(location);
        }
      });
    }
  }
};

export default MapWithLocationSelect;
