import {addDays, getDate, getMonth, getYear, subDays} from 'date-fns';

import {handleFetchResponseErrors} from '../../fetchUtils';
import PhotosService from '../../PhotosService';
import PartialAlbumList from '../../PartialAlbumList';
import AlbumInfo from '../../AlbumInfo';
import PartialPhotoList from '../../PartialPhotoList';

const googlePhotosService: PhotosService = {
  loadPhotosFromAlbum,
  loadPhotosFromDate,
  loadAlbumInfo,
  loadPhotoInfo,
  loadAlbums
};

export default googlePhotosService;

/**
 *
 */
async function loadAlbums(accessToken: string, nextPageToken?: string): Promise<PartialAlbumList> {
  const result = await loadMyGooglePhotoAlbums(accessToken, nextPageToken);
  return {
    albums: result.albums.map((album: GAlbumResponse) => ({
      title: album.title,
      coverBaseUrl: album.coverPhotoBaseUrl,
      id: album.id
    })),
    nextPageToken: result.nextPageToken
  };
}

async function loadAlbumInfo(accessToken: string, albumId: string): Promise<AlbumInfo> {
  const res = await loadGoogleAlbumInfo(accessToken, albumId);
  return {
    url: res.productUrl,
    title: res.title
  };
}

async function loadPhotoInfo(
  accessToken: string,
  photoId: string
): Promise<{id: string; baseUrl: string}> {
  const res = await loadGooglePhotoInfo(accessToken, photoId);
  return {
    id: res.id,
    baseUrl: res.baseUrl
  };
}

async function loadPhotosFromAlbum(
  accessToken: string,
  albumId: string,
  nextPageToken?: string
): Promise<PartialPhotoList> {
  const result = await loadGooglePhotosFromAlbum(accessToken, albumId, nextPageToken);

  const photos = result.mediaItems
    .filter((mItem: any) => mItem.mimeType === 'image/jpeg')
    .map((mItem: any) => ({id: mItem.id, baseUrl: mItem.baseUrl}));

  return {
    photos,
    nextPageToken: result.nextPageToken
  };
}

async function loadPhotosFromDate(
  accessToken: string,
  date: Date,
  nextPageToken?: string
): Promise<PartialPhotoList> {
  const result = await loadGooglePhotosFromDate(accessToken, date, nextPageToken);
  if (result.mediaItems) {
    const photos = result.mediaItems
      .filter((mItem: any) => mItem.mimeType === 'image/jpeg')
      .map((mItem: any) => ({id: mItem.id, baseUrl: mItem.baseUrl}));
    return {
      photos,
      nextPageToken: result.nextPageToken
    };
  } else {
    return {
      photos: [],
      nextPageToken: undefined
    };
  }
}

interface GAlbumResponse {
  id: string;
  title: string;
  coverPhotoBaseUrl: string;
}

/**
 *
 */
async function loadMyGooglePhotoAlbums(accessToken: string, nextPageToken?: string): Promise<any> {
  const query = `?pageSize=50${nextPageToken ? '&pageToken=' + nextPageToken : ''}`;
  let res = await fetch(`https://photoslibrary.googleapis.com/v1/albums${query}`, {
    method: 'GET',
    headers: new Headers({Authorization: 'Bearer ' + accessToken})
  });
  res = await handleFetchResponseErrors(res);

  return res.json();
}

async function loadGooglePhotosFromDate(accessToken: string, date: Date, nextPageToken?: string) {
  const justBeforeDate = subDays(date, 1);
  const justAfterDate = addDays(date, 5);

  const requestBody: any = {
    filters: {
      mediaTypeFilter: {mediaTypes: ['PHOTO']},
      dateFilter: {
        ranges: [
          {
            startDate: {
              year: getYear(justBeforeDate),
              month: getMonth(justBeforeDate) + 1,
              day: getDate(justBeforeDate)
            },
            endDate: {
              year: getYear(justAfterDate),
              month: getMonth(justAfterDate) + 1,
              day: getDate(justAfterDate)
            }
          }
        ]
      }
    },
    pageSize: 100
  };

  if (nextPageToken) {
    requestBody.pageToken = nextPageToken;
  }

  let res = await fetch(`https://photoslibrary.googleapis.com/v1/mediaItems:search`, {
    method: 'POST',
    headers: new Headers({Authorization: 'Bearer ' + accessToken}),
    body: JSON.stringify(requestBody)
  });
  res = await handleFetchResponseErrors(res);
  return res.json();
}

/**
 * See  https://developers.google.com/photos/library/reference/rest/v1/mediaItems/search
 */
async function loadGooglePhotosFromAlbum(
  accessToken: string,
  albumId: string,
  nextPageToken?: string
) {
  const requestBody: any = {
    albumId,
    pageSize: 100
  };

  if (nextPageToken) {
    requestBody.pageToken = nextPageToken;
  }

  let res = await fetch(`https://photoslibrary.googleapis.com/v1/mediaItems:search`, {
    method: 'POST',
    headers: new Headers({Authorization: 'Bearer ' + accessToken}),
    body: JSON.stringify(requestBody)
  });
  res = await handleFetchResponseErrors(res);
  return res.json();
}

/**
 * see https://developers.google.com/photos/library/reference/rest/v1/albums/get
 */
async function loadGoogleAlbumInfo(accessToken: string, albumId: string) {
  let res = await fetch(`https://photoslibrary.googleapis.com/v1/albums/${albumId}`, {
    method: 'GET',
    headers: new Headers({Authorization: 'Bearer ' + accessToken})
  });
  res = await handleFetchResponseErrors(res);
  return res.json();
}

/**
 * see https://developers.google.com/photos/library/reference/rest/v1/mediaItems/get
 */
async function loadGooglePhotoInfo(accessToken: string, photoId: string) {
  let res = await fetch(`https://photoslibrary.googleapis.com/v1/mediaItems/${photoId}`, {
    method: 'GET',
    headers: new Headers({Authorization: 'Bearer ' + accessToken})
  });
  res = await handleFetchResponseErrors(res);
  return res.json();
}
