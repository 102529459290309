import React from 'react';

import {StyledIconButton} from './_styled';

interface IconButtonTypes {
  onClick: (e: React.MouseEvent) => void;
  children?: React.ReactNode;
  className: string;
  title?: string;
  active?: boolean;
  disabled?: boolean;
}

const IconButton = ({onClick, children, className, title, active, disabled}: IconButtonTypes) => (
  <StyledIconButton title={title} onClick={onClick} $active={active} disabled={disabled}>
    <i className={className} />
  </StyledIconButton>
);

export default IconButton;
